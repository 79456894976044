import { render, staticRenderFns } from "./ShipmenInventorytHistory.vue?vue&type=template&id=33c5735e&scoped=true&"
import script from "./ShipmenInventorytHistory.vue?vue&type=script&lang=js&"
export * from "./ShipmenInventorytHistory.vue?vue&type=script&lang=js&"
import style0 from "./ShipmenInventorytHistory.vue?vue&type=style&index=0&id=33c5735e&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33c5735e",
  null
  
)

export default component.exports