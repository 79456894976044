<template>
  <div class="bottom">
    <div @click="clickOne" class="button cancle">
      {{ textOne }}
    </div>
    <div @click="clickTwo" class="button">
      {{ textTwo }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BottomBar',
  props: {
    textOne: {
      type: String,
      default: "取消"
    },
    textTwo: {
      type: String,
      default: "提交"
    }
  },
  methods: {
    clickOne() {
      this.$emit('onClickOne')
    },
    clickTwo() {
      this.$emit('onClickTwo')
    }
  }
}
</script>

 
<style lang="less"  scoped >
.bottom {
  position: fixed;
  bottom: 0;
  height: 56px;
  line-height: 56px;
  display: flex;
  width: 100vw;
  background: @main-color;
  .button {
    background: @main-color;
    color: #FFFFFF;
    text-align: center;
    flex: 1;
    font-size: 16px;
  }
  .cancle {
    background: #EBFFE9;
    color: #6BC263;
  }
}
</style>
