<template>
  <div class="wrapper">
    <SearchBar :params="queryParams.deviceCode" @onSearch="onSearch"  :placeholder="$t('qing-shu-ru-she-bei-hao-huo-zhe-she-bei-ming')" :slotOffset="8" />
    <van-list
      class="item-wrapper"
      v-model="loading"
      :finished="finished"
      :finished-text="$t('mei-you-geng-duo-le')"
      @load="onLoad"
    >
      <Card v-for="item in list" :key="item.deviceCode" class="item">
        <div class="inner">
          <div class="title">{{ item.deviceName? item.deviceName : $t('wei-she-zhi-ming-cheng')}}</div>
            <div class="address">
              <Icon name="icon-location" size="0.32rem" color="#C8CCC8" />
              {{ item.addressInfo? item.addressInfo : $t('wei-she-zhi-di-zhi')}}
            </div>
            <div class="handler">
              <div class="left">
                <div class="number-item">
                  <div class="number">
                    {{ (Number(item.productLowStockRate) * 100).toFixed(1) }}%
                  </div>
                  <div class="number-tips">
                    {{$t('huo-dao-que-huo-shuai')}}
                  </div>
                </div>
                <div class="number-item">
                  <div class="number">
                    {{ (Number(item.materialLowStockRate) * 100).toFixed(1) }}%
                  </div>
                  <div class="number-tips">
                    {{$t('yuan-liao-que-huo-shuai')}}
                  </div>
                </div>
              </div>
              <div class="right">
                <van-button @click="clickJam(item)" class="btn" type="primary" size="small">{{ shipment? '补' : '盘'}}{{$t('yuan-liao')}}</van-button>
                <van-button @click="clickProduct(item)" class="btn" type="primary" size="small">{{ shipment? '补' : '盘'}}{{$t('shang-pin')}}</van-button>
              </div>
            </div>
        </div>
      </Card>
    </van-list>
  </div>
</template>

<script>

import SearchBar from '@/components/SearchBar'
import Card from '@/components/Card'
import Icon from '@/components/Icon'
import { pageMixing } from '@/mixins/page'
import { deviceFetchMixing } from '@/mixins/deviceFetch'

export default {
  name: "ShipmentList",
  components: {
    SearchBar,
    Card,
    Icon
  },
  mixins: [ pageMixing, deviceFetchMixing ],
  created() {
    // 判断是补货还是盘点
    if (this.$route.query.shipment) {
      if (this.$route.query.shipment === 'true') {
        this.shipment = true
      } else {
        this.shipment = false
      }
    }
    if (this.$route.query.deviceCode) {
      this.queryParams.deviceCode = this.$route.query.deviceCode
    }
  },
  data() {
    return {
      shipment: false,
    };
  },
  methods: {
    clickProduct(device) {
      this.$router.push({ path: `/device/shipment/${this.shipment? 'stock' : 'inventory'}`, query: { deviceId: device.id}})
    },
    clickJam(device) {
      this.$router.push({ path: `/device/shipment/replenishmentPump`, query: { deviceId: device.id, deviceName: device.deviceName, isShipment: this.shipment }})
    }
  }
};
</script>

<style lang="less" scoped>
.wrapper {
  background: @main-container-bk-color;
  .item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .item {
      margin-top: 8px;
      width: 343px;
      color: @color-black;
      font-size: 16px;
      .inner {
        margin: 16px;
        .title {
          font-weight: bold;
          margin-bottom: 8px;
        }
        .address {
          display: flex;
          background: #F5F8F5;
          font-size: 12px;
          align-items: center;
          color: #C8CCC8;
          width: fit-content;
          padding-right: 8px;
          border-radius: 1px;
          .address-img {
            width: 12px;
            height: 12px;
            margin: 4px 1px 4px 8px;
          }
        }
        .handler {
          margin-top: 16px;
          display: flex;
          justify-content: space-between;
          .left {
            display: flex;
            .number-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-right: 30px;
              text-align: center;
              .number {
                font-weight: bold;
              }
              .number-tips {
                margin-top: 4px;
                font-size: 12px;
                color: @color-grey;
              }
            }
          }
          .right {
            margin-top: 6px;
            display: flex;
            .btn {
 
              margin-left: 4px;
              background: @main-color;
              color: white;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
 
}
</style>
