<template>
  <div>
    <van-nav-bar
      title="会员点单"
      fixed
      @click-left="onClickLeft"
      left-arrow
      ref="vantop"
      style="z-index: 9999!important;"
    />
    <van-tabs
      @click="tabClick"
      :border="true"
      title-inactive-color="#949994"
      title-active-color="#313331"
      color="#6BC263"
      v-model="tabActive"
      style="margin-top: 50px;"
    >
      <van-tab :title="item.name" v-for="item in tabs" :key="item.queryParams">
      </van-tab>
    </van-tabs>
    <div class="device-errand-wrapper">
      <list-page
        ref="handListView"
        :page.sync="queryParams.pageNum"
        @load="getLoad"
      >
        <div class="order-wrapper" v-for="(item, index) in list" :key="index">
          <div class="deviceName">{{ item.deviceName }}</div>
          <div>
            <div class="order-status">
              <span class="loading complete wait">{{
                item.pickupStatusText
              }}</span>
            </div>
            <div
              class="order-item"
              v-for="product in item.orderItemDtoList"
              :key="product.orderSn"
            >
              <div class="left">
                <div>
                  <img :src="product.smallImg" alt="" />
                </div>
                <div class="center">
                  <div class="name">
                    {{ product.productName }}
                  </div>
                  <div class="types">
                    <div class="type">{{ product.sugarTypeText }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="order-msg" v-if="item.baseMemberAddress">
              <div class="line">
                <div class="prefix">客户姓名：</div>
                <div class="surfix">{{ item.baseMemberAddress.userName }}</div>
              </div>
              <div class="line">
                <div class="prefix">联系电话：</div>
                <div class="surfix">{{ item.baseMemberAddress.telNumber }}</div>
              </div>
              <div class="line">
                <div class="prefix">收货地址：</div>
                <div class="surfix">
                  {{ item.baseMemberAddress.address }}
                </div>
              </div>
              <div class="line">
                <div class="prefix">下单时间：</div>
                <div class="surfix">{{ item.paymentTime }}</div>
              </div>
            </div>
            <div class="handler-block">
              <van-button
                class="button"
                type="primary"
                size="normal"
                v-if="item.pickupStatus == 0"
                @click="updateOrder(item)"
                >开始制作</van-button
              >
              <van-button
                class="button"
                type="primary"
                size="normal"
                v-if="item.pickupStatus == 1"
                @click="updateOrder(item)"
                >开始配送</van-button
              >
              <van-button
                class="button"
                type="primary"
                size="normal"
                v-if="item.pickupStatus == 2"
                @click="updateOrder(item)"
                >配送完成</van-button
              >
              <van-button
                class="button"
                type="danger"
                size="normal"
                @click="cancelOrder(item)"
                v-if="item.pickupStatus != 4"
                >作废</van-button
              >
              <van-button
                class="button"
                type="primary"
                size="normal"
                v-if="item.pickupStatus == 4"
                @click="reMakeOrder(item)"
                >重新制作</van-button
              >
              <van-button
                class="button"
                type="warning"
                size="normal"
                v-if="item.pickStatus == 4"
                @click="refundOrder(item)"
                >退款</van-button
              >
            </div>
          </div>
        </div>
      </list-page>
    </div>
  </div>
</template>

<script>
// import Title from './components/Title'
import {
  listMemberOrder,
  updateMemberOrder,
  voidMemberOrder,
  refundMemberOrder,
} from "@/api/order";
import ListPage from "@/components/ListPage";
import { Dialog } from 'vant';
import { Notify } from 'vant';
export default {
  name: "DeviceErrandCustomer",
  components: {
    ListPage,
  },
  data() {
    return {
      orderShow: true,
      remake: "",
      value: 1,
      tabs: [
        {
          name: "未完成",
          queryParams: "1",
        },
        {
          name: "已完成",
          queryParams: "2",
        },
      ],
      list: [],
      tabActive: 0,
      loading: true,
      finished: false,
      refreshing: false,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        fromType: 2
      },
    };
  },
  async mounted() {
    // this.fetchOrderList();
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },

    tabClick() {
      this.queryParams.pageNum = 1;
      this.getLoad();
    },
    getLoad() {
      this.queryParams.finishStatus = this.tabActive + 1;
      this.$refs.handListView
        .loadData(listMemberOrder(this.queryParams))
        .then((data) => {
          this.list = data;
        });
    },
    updateOrder(row) {
      Dialog.confirm({
        title: "提醒",
        message: "确定要执行本操作吗？",
      })
        .then(() => {
          // on confirm
          updateMemberOrder(row.id).then((res) => {
            if (res.code == 200) {
                Notify({ type: 'success', message: '操作成功' });
                this.queryParams.pageNum = 1;
                this.getLoad()
            }
          });
        })
        .catch(() => {
          // on cancel
        });
      
    },
    reMakeOrder(row) {
      Dialog.confirm({
        title: "提醒",
        message: "确定要执行本操作吗？",
      })
        .then(() => {
          // on confirm
          updateMemberOrder(row.id).then((res) => {
            if (res.code == 200) {
                Notify({ type: 'success', message: '操作成功' });
                this.queryParams.pageNum = 1;
                this.getLoad()
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    cancelOrder(row) {
      Dialog.confirm({
        title: "提醒",
        message: "确定要作废本订单吗？",
      })
        .then(() => {
          // on confirm
          voidMemberOrder(row.id).then((res) => {
            if (res.code == 200) {
              Notify({ type: 'success', message: '操作成功' });
              this.queryParams.pageNum = 1;
              this.getLoad()
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    refundOrder(row) {
      Dialog.confirm({
        title: "提醒",
        message: "确定要退款吗？",
      })
        .then(() => {
          // on confirm
          refundMemberOrder(row.id).then((res) => {
            if (res.code == 200) {
              Notify({ type: 'success', message: '操作成功' });
              this.queryParams.pageNum = 1;
              this.getLoad()
            }
          });
        })
        .catch(() => {
          // on cancel
        });
      
    },
  },
};
</script>

<style lang="less" scoped>
.device-errand-wrapper {
  background: @main-container-bk-color;
  .deviceName {
    color: #303331;
    font-size: 16px;
    font-weight: bold;
    padding: 15px;
  }
  .order-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    background: white;
    padding-bottom: 16px;
    .order-item {
      margin: 16px 16px 0 16px;
      height: 80px;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .left {
        display: flex;
        img {
          height: 80px;
          width: 80px;
        }
        .center {
          margin-left: 12px;
          .name {
            color: #303331;
            font-size: 16px;
            font-weight: bold;
          }
          .types {
            margin-top: 4px;
            font-size: 12px;
            display: flex;
            .type {
              width: 38px;
              height: 20px;
              color: @main-color;
              font-size: 11px;
              text-align: center;
              line-height: 20px;
              background: #ebffe9;
            }
          }
          .msg {
            margin-top: 8px;
            padding: 2px 12px;
            color: #949994;
            background: #f5f8f5;
            border-radius: 2px;
            font-size: 12px;
          }
        }
      }
      .right {
        display: flex;
        flex-direction: column-reverse;
        .button {
          width: 64px;
          height: 26px;
          font-size: 12px;
          padding: 0px;
          margin-bottom: 4px;
          position: relative;
        }
        .badge {
          width: 21px;
          height: 16px;
          position: absolute;
          background: @color-red;
          border: 1px solid #ffffff;
          font-size: 11px;
          color: #fff;
          line-height: 16px;
          border-radius: 15px;
          top: -2px;
          right: -15px;
        }
      }
    }
    .order-msg {
      margin: 12px 16px 0 16px;
      background: #f5f8f5;
      padding: 12px;
      .line {
        display: flex;
        color: #626662;
        font-size: 14px;
        line-height: 22px;
        .prefix {
          width: 70px;
        }
        .surfix {
          width: 213px;
          font-weight: bold;
        }
      }
    }
    .handler-block {
      padding: 12px 16px 0 0;
      display: flex;
      flex-direction: row-reverse;
      .button {
        width: 96px;
        height: 36px;
        margin-left: 10px;

      }
    }
  }
  .order-status {
    font-size: 15px;
    position: absolute;
    right: 16px;
    top: 16px;
    .loading {
      color: #f5ad35;
    }
    .complete {
      color: @main-color;
    }
    .wait {
      color: @color-red;
    }
  }
}
</style>
