import request from '@/utils/request'

export function listOrder(query) {
  return request({
    url: '/operator/order/list',
    method: 'get',
    params: query
  })
}
// 会员点单
export function listMemberOrder(query) {
  return request({
    url: '/mobile/order/list',
    method: 'get',
    params: query
  })
}
export function getOrder(id) {
  return request({
    url: '/operator/order/' + id,
    method: 'get'
  })
}

export function orderStatistics(timeType) {
  return request({
    url: '/operator/order/orderStatistics',
    method: 'get',
    params: {
      timeType: timeType
    }
  })
}

export function lineChartData() {
  return request({
    url: '/operator/home/lineChartData',
    method: 'get',
  })
}

export function refund(orderProductIds) {
  return request({
    url: '/operator/order/refund',
    method: 'put',
    data: orderProductIds
  })
}
export function updateMemberOrder(orderId) {
  return request({
    url: '/mobile/order/updateMemberOrder/' + orderId,
    method: 'post',
  })
}
export function voidMemberOrder(orderId) {
  return request({
    url: '/mobile/order/voidMemberOrder/' + orderId,
    method: 'post',
  })
}
export function refundMemberOrder(orderId) {
  return request({
    url: '/mobile/order/refundMemberOrder/' + orderId,
    method: 'post',
  })
}
export function payCustomerOrder(orderId) {
  return request({
    url: '/mobile/order/payCustomerOrder/' + orderId,
    method: 'post',
  })
}
export function startMakeOrder(orderId) {
  return request({
    url: '/mobile/order/startMakeOrder/' + orderId,
    method: 'post',
  })
}
