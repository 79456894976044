<template>
  <div class="login-wrapper">
    <div class="title">{{$t('login.title')}}</div>

    <van-cell-group :border="false" class="input-wrapper">
      <van-field v-model="form.username" :clickable="true"  :border="true"  :placeholder="$t('common.account')" />
      <van-field v-model="form.password" :clickable="true"  :border="true"  :placeholder="$t('common.password')" type="password" />
    </van-cell-group>

    <div class="button-wrapper">
      <van-button :loading="loginLoading" @click="login" class="button" type="primary">{{$t('common.login')}}</van-button>
    </div>
  </div>
</template>

<script>
import utils from '@/utils'
import { mapActions } from 'vuex'
import { removeToken } from '@/utils/auth'
import { bindWechat } from '@/api/login'

const usernameFiled = 'juiceUsername'
const passwordFiled = 'juicePassword'

export default {
  name: "Login",
  components: {},
  data() {
    return {
      form: {
        username: '',
        password: '',
        from: 'mobile'
      },
      loginLoading: false
    };
  },
  mounted() {
    const username = this.$dao.local.get(usernameFiled)
    const password = this.$dao.local.get(passwordFiled)

    if (username && password) {
      this.form.username = username
      this.form.password = password
    }
  },
  methods: {
    ...mapActions(('user'), [
      'Login'
    ]),
    async login() {
      if (utils.stringIsEmpty(this.form.username)) {
        this.$toast.fail(this.$t('zhang-hao-bu-yun-xu-wei-kong'))
        return
      }
      if (utils.stringIsEmpty(this.form.password)) {
        this.$toast.fail(this.$t('mi-ma-bu-yun-xu-wei-kong'))
        return
      }
      this.loginLoading = true
      removeToken()
      try {
        await this.Login(this.form)
        // 保存账号密码
        this.$dao.local.set(usernameFiled, this.form.username)
        this.$dao.local.set(passwordFiled, this.form.password)
       
        let openId = utils.getQueryString('openId')
         console.log('openId', this.$route.query.openId)
        if (openId) {
          bindWechat(openId)
        }
        this.$router.replace('/home');
      } catch(e) {
        console.log(e)
      }
      this.loginLoading = false
    }
  }
};
</script>

<style lang="less" scoped>
.login-wrapper {
  padding: 16px;
  .title {
    padding-top: 32px;
    color: @color-black;
    font-size: 24px;
    font-weight: bold;
  }
  .input-wrapper {
    margin-top: 32px;
  }
  .button-wrapper {
    width: 100%;
    text-align: center;
    .button {
      width: 343px;
      margin-top: 40px;
      text-align: center;
    }
  }

}
</style>
