<template>
  <div>
    <div class="search-bar">
      <div class="main">
        <div @click="back" class="back"><van-icon name="arrow-left" /></div>
        <div class="input">
            <van-field clearable v-model="searchParams" class="input-box" :placeholder="placeholder" />
        </div>
        <div @click="onSearch" class="button">{{$t('sou-suo')}}</div>
      </div>
      <slot></slot>
    </div>
    <div class="offset" :style="{ paddingBottom: offsetMargin}"></div>
  </div>
</template>

<script>
export default {
  name: 'SearchBar',
  props: {
    params: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: '请输入设备号或者设备名'
    },
    slotOffset: {
      type: Number,
      default: 0
    }
  },
  computed: {
    offsetMargin() {
      return `${this.slotOffset + 56}px`
    }
  },
  mounted() {
    this.searchParams = this.params
  },
  data() {
    return {
      searchParams: ''
    }
  },
  watch: {
    searchParams(val) {
      this.$emit('update:params', val)
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    onSearch() {
      this.$emit('onSearch', this.searchParams)
    }
  }
}
</script>

 
<style lang="less"  scoped >
.search-bar {
  width: 100vw;
  position: fixed;
  z-index: 999;
  top: 0;

  .main {
    background: #ffffff;
    height: 56px;
    display: flex;
    font-size: 16px;
    color: @color-black;
    align-items: center;
    .back {
      text-align: center;
      width: 52px;
    }
    .input {
      width: calc(~"100% - 52px - 60px");
      .input-box {
        background: #F5F8F5;
        border-radius: 4px;
      }
    }
    .button {
      padding-left: 16px;
      width: 60px;
    }
  }
}
.offset {
  background: @main-container-bk-color;
}
</style>
