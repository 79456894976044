<template>
  <div class="wrapper">
    <van-tabs  @click="tabClick" :border="false" title-inactive-color="#949994" title-active-color="#313331" color="#6BC263"   v-model="tabActive" >
      <van-tab :title="item.name" v-for="item in tabs" :key="item.id">
      </van-tab>
    </van-tabs>
    <van-list
      class="item-wrapper"
      v-model="loading"
      :finished="finished"
      :finished-text="$t('mei-you-geng-duo-le')"
      @load="onLoad"
    >
      <Card v-for="i in list" :key="i.id" class="item">
        <div class="inner"  @click="toDesc(i.id)">
          <div class="left">
            <div class="title">{{ i.deviceName }}</div>
            <div class="date">{{ i.createTime }}</div>
          </div>
          <div class="right">
            <van-icon name="arrow" />
            <!-- <div class="num"><van-icon name="arrow" /></div>
            <div class="tip">原料果酱</div> -->
          </div>
        </div>
      </Card>
    </van-list>
  </div>
</template>

<script>

import Card from '@/components/Card'
import { pageMixing } from '@/mixins/page'
import { refillList, checkList } from '@/api/refill'
import { getCurrent, getYesterday, getCurrentWeek, getCurrentMonth } from '@/utils/date'

const currenDate = getCurrent()
const yesterDate = getYesterday()
const weekDate = getCurrentWeek()
const montyDate = getCurrentMonth()

export default {
  name: "ShipmentList",
  components: {
    Card
  },
  mixins: [ pageMixing ],
  created() {
    // 判断是补货还是盘点
    this.isShipment = this.$route.query.shipment == 'true'
  },
  data() {
    return {
      isShipment: false,
      tabActive: undefined,
      tabs: [
        {
          id: 1,
          name: this.$t('jin-ri'),
          startTime: currenDate[0],
          endTime: currenDate[1],
        },
        {
          id: 2,
          name: this.$t('zuo-ri'),
          startTime: yesterDate[0],
          endTime: yesterDate[1],
        },
        {
          id: 3,
          name: this.$t('ben-zhou'),
          startTime: weekDate[0],
          endTime: weekDate[1],
        },
        {
          id: 4,
          name: this.$t('ben-yue'),
          startTime: montyDate[0],
          endTime: montyDate[1],
        },
      ],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        startTime: currenDate[0],
        endTime: currenDate[1],
      },
    };
  },
  methods: {
    injectFetch() {
      if (this.isShipment) {
        return refillList(this.queryParams)
      } else {
        return checkList(this.queryParams)
      }
    },
    functionClick(source) {
      this.$router.push(source.path)
    },
    onSearch(deviceCode) {
      console.log(deviceCode)
      this.fetch()
    },
    tabClick(index) {
      const { startTime, endTime } = this.tabs[index]
      this.queryParams.startTime = startTime
      this.queryParams.endTime = endTime
      this.list = []
      this.fetch()
    },
    toDesc(id) {
      this.$router.push({ path: '/device/shipment/desc', query: { id, isShipment: this.isShipment }})
    }
  }
};
</script>

<style lang="less" scoped>
.wrapper {
  background: @main-container-bk-color;
  .item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .item {
      margin-top: 8px;
      width: 343px;
      color: @color-black;
      font-size: 16px;
      .inner {
        margin: 16px;
        display: flex;
        justify-content: space-between;
        .left {
          .date {
            margin-top: 8px;
            color: @color-grey;
            font-size: 14px;
          }
        }
        .right {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .tip {
            color: @color-grey;
            margin-top: 8px;
            font-size: 12px;
          }
        }
      }
    }
  }
 
}
</style>
