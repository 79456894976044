Date.prototype.Format = function(fmt)
{ //author: meizz
    var o = {
        "M+" : this.getMonth()+1,                 //月份
        "d+" : this.getDate(),                    //日
        "h+" : this.getHours(),                   //小时
        "m+" : this.getMinutes(),                 //分
        "s+" : this.getSeconds(),                 //秒
        "q+" : Math.floor((this.getMonth()+3)/3), //季度
        "S"  : this.getMilliseconds()             //毫秒
    };
    if(/(y+)/.test(fmt))
        fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length));
    for(var k in o)
        if(new RegExp("("+ k +")").test(fmt))
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
    return fmt;
}


export function getCurrentDate() {
  return new Date();
}

export function getCurrentWeek() {
  //起止日期数组
  var startStop = new Array();
  //获取当前时间
  var currentDate = getCurrentDate();
  //返回date是一周中的某一天
  var week = currentDate.getDay();

  //一天的毫秒数
  var millisecond = 1000 * 60 * 60 * 24;
  //减去的天数
  var minusDay = week != 0 ? week - 1 : 6;
  //alert(minusDay);
  //本周 周一
  var monday = new Date(currentDate.getTime() - (minusDay * millisecond));
  //本周 周日
  var sunday = new Date(monday.getTime() + (6 * millisecond));
  //添加本周时间
  startStop.push(monday.Format("yyyy-MM-dd 00:00")); //本周起始时间
  //添加本周最后一天时间
  startStop.push(sunday.Format("yyyy-MM-dd 23:59")); //本周终止时间
  //返回
  return startStop;
}

export function getCurrentMonth() {
  //起止日期数组
  var startStop = new Array();
  //获取当前时间
  var currentDate = getCurrentDate();
  //获得当前月份0-11
  var currentMonth = currentDate.getMonth();
  //获得当前年份4位年
  var currentYear = currentDate.getFullYear();
  //求出本月第一天
  var firstDay = new Date(currentYear, currentMonth, 1);


  //当为12月的时候年份需要加1
  //月份需要更新为0 也就是下一年的第一个月
  if (currentMonth == 11) {
      currentYear++;
      currentMonth = 0; //就为
  } else {
      //否则只是月份增加,以便求的下一月的第一天
      currentMonth++;
  }


  //一天的毫秒数
  var millisecond = 1000 * 60 * 60 * 24;
  //下月的第一天
  var nextMonthDayOne = new Date(currentYear, currentMonth, 1);
  //求出上月的最后一天
  var lastDay = new Date(nextMonthDayOne.getTime() - millisecond);

  //添加至数组中返回
  startStop.push(firstDay.Format("yyyy-MM-dd 00:00"));
  startStop.push(lastDay.Format("yyyy-MM-dd 23:59"));
  //返回
  return startStop;
}

/**
 * 获取当天的起止时间
 */
export function getCurrent() {
  var startStop = new Array();
  const start = new Date().Format("yyyy-MM-dd 00:00");//今日开始
  const end = new Date().Format("yyyy-MM-dd 23:59");//今日结束
  //添加至数组中返回
  startStop.push(start);
  startStop.push(end);
  //返回
  return startStop;
}

export function getYesterday() {
  var startStop = new Array();
  var today = new Date();
  var yesterday = new Date(today.setTime(today.getTime()-24*60*60*1000));                     
  const start = yesterday.Format("yyyy-MM-dd 00:00");//昨日开始
  const end = yesterday.Format("yyyy-MM-dd 23:59") ;//昨日结束
  startStop.push(start);
  startStop.push(end);
  //返回
  return startStop;
}