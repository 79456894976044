<template>
  <div class="device-wrapper">
    <SearchBar @onSearch="onSearch"  :placeholder="$t('qing-shu-ru-she-bei-hao-huo-zhe-she-bei-ming')" :slotOffset="12 + 98">
      <div class="selected">
        <DeviceBlock @click.native="deviceBlockClick('online')" :selected="deviceBlockStatus.onlineSelected" :large="false" title="在线设备" :number="deviceCount.online" type="online"/>
        <DeviceBlock @click.native="deviceBlockClick('offline')" :selected="deviceBlockStatus.offlineSelected" :large="false" title="离线设备" :number="deviceCount.offline" type="stop"/>
        <DeviceBlock @click.native="deviceBlockClick('error')" :selected="deviceBlockStatus.errorSelected" :large="false" title="故障设备" :number="deviceCount.error" type="error"/>
        <DeviceBlock @click.native="deviceBlockClick('stop')" :selected="deviceBlockStatus.stopSelected" :large="false" title="停售设备" :number="deviceCount.stop" type="stop"/>
      </div>
    </SearchBar>

    <div class="main">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div v-for="item in list" :key="item.id" class="device-wrapper" >
          <div class="device-item" >
            <div class="top">
              <div class="title">{{ item.deviceName ? item.deviceName : '未设置名称'}}</div>
              <div class="online-tips-wrapper">
                
                <Icon name="icon-sign" size="14px"  :color="item.onlineStatus? '#6BC263' : '#C8CCC8'" style="margin-right: 8px" />
                <Icon name="icon-stock" size="14px" :color="item.productStatus? '#6BC263' : '#FFC96B'" style="margin-right: 8px"/>
                <Icon name="icon-device" size="14px" :color="!item.errorStatus? '#6BC263' : '#FF7C6B'"/>
                <div class="online-tips"></div>
              </div>
            </div>
            <div class="inner-wrapper">
              <div class="item" @click="clickDeviceItem(item.id)">
                <div class="title">
                  <div class="prefix">{{item.deviceCode}}</div>
                  <div class="surfix">
                    <!-- <img src="@/assets/device/icon_online.png" alt=""> -->
                    <!-- <Icon name="icon-stock" size="14px" :color="item.productStatus? '#6BC263' : '#FFC96B'"/> -->
                  </div>
                </div>
                
                <div class="desc">
                  <div>
                    <span>商品库存：{{item.totalProductStock}}/{{item.totalRailCapacity}}</span> | <span> 商品缺货率：{{ (Number(item.productLowStockRate) * 100).toFixed(1) }}%</span>
                  </div>
                  <div>
                    <span>水1：{{item.waterStock1}}ml</span> | <span>水2：{{item.waterStock2}}ml</span> | <span>水3：{{item.waterStock3}}ml</span>
                  </div>
                  <div>
                    <span> 杯盖：{{ item.cupCoverStock}}个 </span> | <span> 吸管：{{ item.strawStock}}个</span>
                  </div>
                  <div>
                    <span>{{item.jamsStock}}</span>
                  </div>
                </div>
          
              </div>
            </div>
            <div style="margin-right: 16px; margin-top: 16px; display: flex; justify-content: end;">
              <van-button  v-if="item.errorStatus" type="warning" size="mini" @click="onCLickError(item.deviceCode)">查看错误</van-button>
              <van-button type="info" size="mini" @click="onCLickDevicePortInfo(item.deviceCode)">机器状态</van-button>
            </div>
          </div>
        </div>
      </van-list>

    </div>



    <van-dialog v-model="errorDialog.show" :show-cancel-button="false" title="错误日志">
      <van-cell-group inset style="margin-top: 10px;">
        <van-cell title="日志" :value="errorDialog.content" />
        <van-cell title="上报时间" :value="errorDialog.time"  />
      </van-cell-group>
    </van-dialog>

    <van-dialog v-model="deviceInfoDialog.show" :show-cancel-button="false" title="机器状态">
      <van-cell-group inset style="margin-top: 10px;">
        <van-cell title="2101" :value="deviceInfoDialog._2101" />
        <van-cell title="2102" :value="deviceInfoDialog._2102"  />
        <van-cell title="2129" :value="deviceInfoDialog._2129"  />
        <van-cell title="连接" :value="deviceInfoDialog.portStatus"  />
        <van-cell title="错误解析" :value="deviceInfoDialog.machineError"  />
      </van-cell-group>
    </van-dialog>

  </div>
</template>

<script>
import DeviceBlock from '@/components/DeviceBlock'
import SearchBar from '@/components/SearchBar'
import Icon from '@/components/Icon'
import { deviceCountByType, getDeviceError, getDevicePortInfo } from '@/api/device'
import { pageMixing } from '@/mixins/page'
import { deviceFetchMixing } from '@/mixins/deviceFetch'
export default {
  name: "DeviceIndex",
  mixins: [ pageMixing, deviceFetchMixing ],
  components: {
    DeviceBlock,
    SearchBar,
    Icon
  },
  data() {
    return {
      errorDialog: {
        show: false,
        content: '',
        time: ''
      },
      deviceInfoDialog: {
        show: false,
        machineError: '',
        portStatus: '',
        _2101: '',
        _2102: '',
        _2129: ''
      },
      activeName: '1',
      deviceBlockStatus: {
        onlineSelected: false,
        offlineSelected: false,
        errorSelected: false,
        stopSelected: false,
      },
      deviceCount: {},
      fetchStatus: false
    };
  },
  async mounted() {
    const { data } = await deviceCountByType()
    this.deviceCount = data
  },
  methods: {
    deviceBlockClick(name) {
      if (this.fetchStatus) {
        // 如果是正在刷新的时候不允许点击
        return
      }
      switch(name) {
        case 'online':
          // 在线和离线不可以同时选中
          if (this.deviceBlockStatus.offlineSelected) {
            // this.deviceBlockClick('offline')
            this.deviceBlockStatus.offlineSelected = !this.deviceBlockStatus.offlineSelected
          }
          this.deviceBlockStatus.onlineSelected = !this.deviceBlockStatus.onlineSelected
          if (this.deviceBlockStatus.onlineSelected) {
            // 只查询在线的
            this.queryParams.onlineStatus = 1
          } else {
            this.queryParams.onlineStatus = undefined
          }
          this.handlerTopBlockSearch()
          break;
        case 'offline':
          // 在线和离线不可以同时选中
          if (this.deviceBlockStatus.onlineSelected) {
            // this.deviceBlockClick('online')
            this.deviceBlockStatus.onlineSelected = !this.deviceBlockStatus.onlineSelected
          }
          this.deviceBlockStatus.offlineSelected = !this.deviceBlockStatus.offlineSelected
          if (this.deviceBlockStatus.offlineSelected) {
            this.queryParams.onlineStatus = 0
          } else {
            this.queryParams.onlineStatus = undefined
          }
          this.handlerTopBlockSearch()
          break;
        case 'error':
          this.deviceBlockStatus.errorSelected = !this.deviceBlockStatus.errorSelected
          if (this.deviceBlockStatus.errorSelected) {
            this.queryParams.errorStatus = 1
          } else {
            this.queryParams.errorStatus = undefined
          }
          this.handlerTopBlockSearch()
          break;
        case 'stop':
          this.deviceBlockStatus.stopSelected = !this.deviceBlockStatus.stopSelected
          if (this.deviceBlockStatus.stopSelected) {
            this.queryParams.salesStatus = 0
          } else {
            this.queryParams.salesStatus = undefined
          }
          this.handlerTopBlockSearch()
          break;
      }
    },
    clickDeviceItem(deviceId) {
      this.$router.push({ path: '/device/status', query: { deviceId } });
    },
    async onCLickError(deviceCode) {
      const res = await getDeviceError(deviceCode)
      console.log(res)
      this.errorDialog.show = true
      this.errorDialog.content = res.data.errorMsg
      this.errorDialog.time = res.data.createTime
    },
    async onCLickDevicePortInfo(deviceCode) {
      const res = await getDevicePortInfo(deviceCode)
      this.deviceInfoDialog.machineError = res.data.machineError
      this.deviceInfoDialog.portStatus = res.data.portStatus
      this.deviceInfoDialog._2101 = res.data._2101
      this.deviceInfoDialog._2102 = res.data._2102
      this.deviceInfoDialog._2129 = res.data._2129
      this.deviceInfoDialog.show = true
    },
    handlerTopBlockSearch() {
      this.fetchStatus = true
      this.queryParams.pageNum = 1
      this.list = []
      this.fetch()
    },
    pageFetchComplete() {
      this.fetchStatus = false
    }
  }
};
</script>

<style lang="less" scoped>
.device-wrapper{
  margin-bottom: 8px;
  .selected {
    padding-top: 12px;
    padding-bottom: 5px;
    display: grid;
    grid-template-columns: repeat(4, 25%);
    grid-row-gap: 8px;
    justify-items: center;
    background: @main-container-bk-color;
  }
  .main {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .device-wrapper {
      width: 343px !important;
      .device-item {
        background:white;
        border-radius: 4px;
        width: 343px;
        padding-bottom: 16px;
        .top {
          padding: 16px 0 12px 16px;
          display: flex;
          justify-content: space-between;
          .title {
            font-weight: bold;
            font-size: 18px;
          }
          .online-tips-wrapper {
            color: @color-grey;
            font-size: 14px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            img {
              width: 14px;
              height: 14px;
            }
            .online-tips {
              margin: 0 12px 0 4px;
            }
          }
        }
        .inner-wrapper {
          margin: 0 .5rem;
          background: @main-container-bk-color;
          .item {
            padding: 12px 16px 16px 12px;
            .title {
              color: @color-black;
              font-size: .5rem;
              font-weight: bold;
              display: flex;
              align-items: center;
              justify-content: space-between;
              img {
                width: 14px;
                height: 14px;
              }
            }
            .desc {
              margin-top: 12px;
              color: @color-grey;
              font-size: 12px;
              line-height: .6rem;
            }
          }
        }
      }
    }

  }
}

</style>
