<template>
  <div>
    <van-nav-bar
      title="下单成功"
      fixed
      @click-left="onClickLeft"
      left-arrow
      ref="vantop"
      style="z-index: 9999!important;"
    />
    <div class="mainContent">
      <div><p>下单成功, 请点击立即制作或稍后在订单列表中点击立即制作让机器开始制作</p></div>
      <div>
        <van-button type="primary" block @click="makeNow">立即制作</van-button>
        <br/>
        <van-button type="primary" plain block @click="addOrder">继续下单</van-button>
        <br/>
        <van-button type="default" block @click="toOrder">返回订单</van-button>
      </div>
    </div>
    
  </div>
</template>

<script>
import { Dialog } from 'vant';
import { startMakeOrder } from "@/api/order";
import { Notify } from 'vant';
export default {
  name: "DeviceErrandCustomer",
  components: {
  },
  data() {
    return {
      
    };
  },
  async mounted() {
    // this.fetchOrderList();
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },

    tabClick() {
      this.queryParams.pageNum = 1;
      this.getLoad();
    },
    addOrder() {
      this.$router.push({ path: "./Customer"});
    },
    toOrder() {
      this.$router.push({ path: "./CustomerOrder"});
    },
    makeNow() {
       Dialog.confirm({
        title: "提醒",
        message: "确定要开始制作本单吗？",
      })
        .then(() => {
          // on confirm
          startMakeOrder(this.$route.query.orderId).then((res) => {
            if (res.code == 200) {
                Notify({ type: 'success', message: '操作成功, 请关注制作进度！' });
                this.toOrder()
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    }
  },
};
</script>

<style lang="less" scoped>

.mainContent {
    text-align: center;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;

  }
</style>
