<template>
  <div class="wrapper">
    <SearchBar @onSearch="onSearch" :placeholder="$t('qing-shu-ru-ding-dan-hao')" :slotOffset="44 + 12 + 8">
      <div class="filter-wrapper">
        <div class="filter" @click="filter('date')">{{this.filterParams.startTime ? $t('yi-xuan-ze-ri-qi') : $t('ri-qi-xuan-ze')}}<van-icon class="icon" name="arrow-down" /></div>
        <div class="filter center" @click="filter('device')">{{this.filterParams.deviceName ? this.filterParams.deviceName : $t('she-bei-xuan-ze')}}<van-icon class="icon" name="arrow-down" /></div>
        <div class="filter" @click="filter('payStatus')" >{{this.filterParams.payStatusName ? this.filterParams.payStatusName : $t('zhi-fu-zhuang-tai')}}<van-icon class="icon" name="arrow-down" /></div>
      </div>
    </SearchBar>
    
    <van-list
      class="item-wrapper"
      v-model="loading"
      :finished="finished"
      :finished-text="$t('mei-you-geng-duo-le')"
      @load="onLoad"
    >
      <div class="item" v-for="item in list" :key="item.id">
        <div class="top">
          <div class="code">{{$t('dan-hao')}}：{{item.orderSn}}</div>
          <div class="type">{{item.payTypeText}}支付</div>
        </div>
        <div class="line"></div>
        <div class="content-wrapper">
          <div class="content" v-for="product in item.orderItemDtoList" :key="product.id">
            <div class="producgt-img">
              <div class="img-wrapper">
                <img :src="product.smallImg" alt="">
              </div>
            </div>
            <div class="desc">
              <div class="product-name">{{ product.productName }}</div>
              <div class="tags">
                <div v-if="product.payStatus === 201" class="tag-success">{{$t('yi-zhi-fu')}}</div>
                <div v-else-if="product.payStatus === 203" class="tag-error">{{$t('yi-tui-kuan')}}</div>

  
                <div v-if="product.shipmentStatus === 1" class="tag-success">{{$t('chu-huo-cheng-gong')}}</div>
                <div v-else-if="product.shipmentStatus === 0" class="tag-error">{{$t('chu-huo-shi-bai')}}</div>
                <div v-else-if="product.shipmentStatus === 3" class="tag-warning">未报告</div>
                <div v-else-if="product.shipmentStatus === 5" class="tag-warning">未制作</div>

              </div>

              <div class="from">{{$t('lai-zi')}}{{item.deviceName}}</div>
              <div class="price">￥{{product.productPriceYuan}}</div>
            </div>
            <div class="refund-check">
              <van-checkbox v-model="product.refundChecked" :disabled="product.payStatus !== 201" checked-color="#6BC263" ></van-checkbox>
            </div>
          </div>
        </div>
        <div class="line"></div>
        <div class="bottom">
          <div class="time">{{item.paymentTime}}</div>
          <div class="price">
            {{$t('zong-jin-e')}}：<span class="money">￥{{item.payAmountYuan}}</span>
          </div>
          <van-button color="#6BC263" :disabled="checkRefundButton(item)" :loading="refundLoading" @click="refund(item)" class="refund" type="primary" size="small">{{$t('tui-kuan')}}</van-button>
        </div>
      </div>
    </van-list>

    <van-popup v-model="pupup.device" position="bottom" :style="{ height: '30%' }" >
      <van-picker
        value-key="deviceName"
        :title="$t('qing-xuan-ze-she-bei')"
        show-toolbar
        :columns="deviceList"
        @confirm="onFilterDevice"
      />
    </van-popup>


    <van-popup v-model="pupup.payStatus" position="bottom" :style="{ height: '30%' }" >
      <van-picker
        value-key="name"
        :title="$t('qing-xuan-ze-zhi-fu-fang-shi')"
        show-toolbar
        :columns="payStatusList"
        @confirm="onFilterPayStatus"
      />
    </van-popup>

    <!-- <van-popup v-model="pupup.date" position="bottom" :style="{ height: '30%' }" >
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
      />
    </van-popup> -->


    <van-calendar :min-date="minDate" :max-date="maxDate"  color="#6BC263" v-model="pupup.date" type="range" @confirm="onConfirm" allow-same-day  />
  </div>
</template>

<script>
 
import SearchBar from '@/components/SearchBar'
import { listOrder, refund } from '@/api/order'
import { listDevice } from '@/api/device'
import { pageMixing } from '@/mixins/page'
export default {
  name: "Order",
  components: {
    SearchBar
  },
  mixins: [ pageMixing ],
  data() {
    return {
      deviceList: [],
      payStatusList: [
        {
          name: this.$t('suo-you-zhi-fu-zhuang-tai')
        },
        {
          name: this.$t('yi-fu-kuan'),
          code: 201
        },
        {
          name: this.$t('yi-tui-kuan'),
          code: 203
        },
        {
          name: this.$t('bu-fen-tui-kuan'),
          code: 205
        },
      ],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        deviceId: undefined,
        orderSn: undefined,
        payAmount: undefined,
        totalAmount: undefined,
        refundAmount: undefined,
        payType: undefined,
        payStatus: undefined,
        paymentTime: undefined,
        startTime: undefined,
        endTime: undefined
      },
      filterParams: {
        deviceName: undefined,
        payStatusName: undefined,
        startTime: undefined
      },
      pupup: {
        device: false,
        payStatus: false,
        date: false
      },
      minDate: new Date(2008, 8, 8),
      maxDate: new Date(),
      currentDate: new Date(),
      refundLoading: false
    };
  },
  computed: {
    checkRefundButton() {
      return (item) => {
        // 判断是否有checkBox选中了
        let reult = true
        for(let i of item.orderItemDtoList) {
          if(i.refundChecked) {
            reult = false
            break
          }
        }
        return reult
      }
    },
  },
  mounted () {
    this.list = []
    this.fetchDevice()
  },
  methods: {
    injectFetch() {
      return listOrder(this.queryParams)
    },
    async fetchDevice() {
      let { rows } = await listDevice({ pageSize: 999999 })
      rows = rows.filter(item => {
        return item.deviceName
      })
      this.deviceList.push({deviceName: this.$t('suo-you-she-bei')})
      this.deviceList.push(...rows)
    },
    afterFetch(rows, total) {
      rows.forEach(row => {
        row.orderItemDtoList.forEach(item => {
          item['refundChecked'] = false
        })
      });
      this.list.push(...rows)
      this.loading = false;
      if (this.list.length >= total) {
        this.finished = true
      }
    },
    onSearch(orderSn) {
      this.resetParams()
      this.queryParams.orderSn = orderSn
      this.list = []
      this.fetch()
    },
    filter(taget) {
      switch(taget) {
        case 'date':
          this.pupup.date = true
          break
        case 'device':
          this.pupup.device = true
          break
        case 'payStatus':
          this.pupup.payStatus = true
      }
    },
    onFilterDevice(device) {
      this.queryParams.pageNum = 1
      if (device.id) {
        this.queryParams.deviceId = device.id
      } else {
        this.queryParams.deviceId = undefined
      }
      this.filterParams.deviceName = device.deviceName
      this.list = []
      this.fetch()
      this.pupup.device = false
    },
    onFilterPayStatus(status) {
      this.queryParams.pageNum = 1
      if (status.code) {
        this.queryParams.payStatus = status.code
      } else {
        this.queryParams.payStatus = undefined
      }
      this.filterParams.payStatusName = status.name
      this.list = []
      this.fetch()
      this.pupup.payStatus = false
    },
    onConfirm(range) {

      this.queryParams.pageNum = 1
      this.queryParams.startTime = range[0].Format("yyyy-MM-dd 00:00")
      this.queryParams.endTime = range[1].Format("yyyy-MM-dd 23:59")
      this.filterParams.startTime = range[0]
      this.list = []
      this.fetch()
      this.pupup.date = false
    },
    resetParams() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 10,
        deviceId: undefined,
        orderSn: undefined,
        payAmount: undefined,
        totalAmount: undefined,
        refundAmount: undefined,
        payType: undefined,
        payStatus: undefined,
        paymentTime: undefined,
        startTime: undefined,
        endTime: undefined
      }
      this.filterParams =  {
        deviceName: undefined,
        payStatusName: undefined,
        startTime: undefined
      }
    },
    async refund(item) {
      console.log(item)
      this.$dialog.confirm({
        title: this.$t('tui-kuan-que-ren'),
        message: this.$t('que-ren-gei-suo-xuan-shang-pin-tui-kuan-ma'),
      })
      .then(async () => {
        this.refundLoading = true
        let refundIds = []
        item.orderItemDtoList.forEach(item => {
          if(item.refundChecked) {
            refundIds.push(item.id)
          }
        })
        console.log(refundIds)
        try {
          await refund(refundIds)
          this.resetParams()
          this.list = []
          this.fetch()
        } catch (e) {
          console.log(e)
        }
        this.refundLoading = false
      }).catch(() => {
      });
    }
    
  }
};
</script>

<style lang="less" scoped>
.wrapper {
  .filter-wrapper {
    height: 44px;
    background: white;
    font-size: 14px;
    color: @color-grey;
    display: flex;
    justify-content: center;
    align-items: center;
    .filter {
      display: flex;
      align-items: center;
      .icon {
        margin-left: 2px;
        display: block;
        height: 100%;
      }
    }
    .center {
      margin: 0 42px;
    }
  }
  .item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  
    .item {
      background: white;
      color: @color-grey;
      width: 345px;
      margin: 0 16px 8px 16px;
      .top {
        padding: 0 16px;
        margin: 12px 0;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
      }
      .content-wrapper {
        .content {
          padding: 0 16px;
          margin-top: 16px;
          display: flex;
          .producgt-img {
            .img-wrapper {
              width: 80px;
              height: 80px;
              border: 1px solid #EBEDEB;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 70px;
                height: 70px
              }
            }
          }
          .desc {
            margin-left: 16px;
            .product-name {
              font-size: 16px;
              color: @color-black;
              font-weight: bold;
            }
            .tags {
              margin-top: 8px;
              font-size: 11px;
              display: flex;
              .tag-success {
                padding: 4px 8px;
                background: #EBFFE9;
                color: @main-color;
                margin-right: 8px;
              }
              .tag-error {
                padding: 4px 8px;
                background: #FFECE9;
                color: @color-red;
                margin-right: 8px;
              }
              .tag-warning {
                padding: 4px 8px;
                background: #fff5e9;
                color: @color-red;
                margin-right: 8px;
              }
            }
            .from {
              margin-top: 8px;
              background: #F5F8F5;
              font-size: 12px;
              color: @color-grey;
              padding: 5px 8px;
            }
            .price {
              margin-top: 12px;
              margin-bottom: 16px;
              color: #626662;
              font-size: 18px;
            }
          }
          .refund-check {
            padding-left: 20px;
            align-self: center;
          }
        }
      }
      .bottom {
        padding: 0 16px;
        display: flex;
        font-size: 14px; 
        height: 62px;
        align-items: center;
        justify-content: space-between;
        .time {
          color: #C8CCC8;
          
        }
        .money {
          color: @color-red;
          font-size: 18px;
          font-weight: bold;
        }
        .refund {
          width: 60px;
          height: 30px;
        }
      }
      .line {
        margin: 0 16px;
        border-top: 1px solid #EBEDEB;
      }
    }
  }
}
</style>
