<template>
  <div>
    <div class="top">
      <HomeTopCard>
        <template #header>
          <div class="header"></div>
        </template>
        <template #main>
          <div class="avatar">
            <img :src="avatar" alt="">
          </div>
          <div class="top name">
            {{name}}
          </div>
          <div class="bottom" >
            <div class="item">
              <div class="number" v-if="dataModel.deviceCount != undefined">{{dataModel.deviceCount}}</div>
              <div class="number" v-else> <van-loading type="spinner" size="24px" /> </div>
              <div class="desc">{{$t('she-bei-shu')}}</div>
            </div>
            <div class="item">
              <div class="number" v-if="dataModel.totalSaleAmount != undefined">{{dataModel.totalSaleAmount}}</div>
              <div class="number" v-else> <van-loading type="spinner" size="24px" /> </div>
              <div class="desc">{{$t('xiao-shou-e')}}</div>
            </div>
            <div class="item">
              <div class="number" v-if="dataModel.msgCount != undefined">{{dataModel.msgCount}}</div>
              <div class="number" v-else> <van-loading type="spinner" size="24px" /> </div>
              <div class="desc">{{$t('xiao-xi-shu')}}</div>
            </div>
          </div>
        </template>
      </HomeTopCard>
    </div>
    <FunctionsGrid  :functions="functions"/>
    <BottomTab />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BottomTab from './components/BottomTab'
import HomeTopCard from './components/HomeTopCard'
import FunctionsGrid from '@/components/FunctionsGrid'
import { getUserData } from '@/api/device'

export default {
  name: "Home",
  components: {
    BottomTab,
    HomeTopCard,
    FunctionsGrid
  },
  data() {
    return {
      functions: [
        {
          id: 1,
          icon: require('@/assets/home/icon_setting.png'),
          name: this.$t('she-zhi'),
          path: '/my/setting',
          target: this.functionClick
        },
        {
          id: 2,
          icon: require('@/assets/home/icon_question.png'),
          name: this.$t('chang-jian-wen-ti')
        },
        {
          id: 3,
          icon: require('@/assets/home/icon_about.png'),
          name: this.$t('guan-yu-wo-men'),
          path: '/my/about',
          target: this.functionClick
        },
      ],
      dataModel: {
        deviceCount: undefined,
        totalSaleAmount: undefined,
        msgCount: undefined,
      }
    };
  },
  computed: {
    ...mapState('user', [
      'name',
      'avatar'
    ])
  },
  created() {
   
  },
  async mounted() {
    const { data } = await getUserData()
    this.dataModel = data
  },
  methods: {
    functionClick(source) {
      this.$router.push(source.path)
    }
  }
};
</script>

<style lang="less" scoped>
.top {
  position: relative;
  .header {
    height: 46px;
  }
  .avatar {
    top: 20px;
    position: absolute;
    img {
      width: 60px;
      height: 60px;
      border: 4px solid @main-color;
      border-radius: 50%;
    }
  }
  .name {
    margin-top: 44px !important;
    margin-bottom: 40px !important;
    font-size: 18px !important;
  }
  .bottom{
    .item {
      .number {
        color: @main-color !important;
      }
    }
  }
}

</style>
