<template>
  <div class="wrapper">
     <div class="block-wrapper">
      <DeviceBlock :title="$t('shang-pin-que-huo-she-bei')" icon="icon-stock" :number="lowStockDeviceCount.productDeviceCount" type="shipment"/>
      <DeviceBlock :title="$t('yuan-liao-que-huo-she-bei')" icon="icon-jam" :number="lowStockDeviceCount.productSourceCount" type="shipmentProduct"/>
     </div>
     <div class="title">
       {{$t('gong-neng-qu')}}
     </div>
     <div class="funcations">
       <FunctionsGrid  :functions="functions" />
     </div>
  </div>
</template>

<script>

import DeviceBlock from '@/components/DeviceBlock'
import FunctionsGrid from '@/components/FunctionsGrid'
import { deviceCountOfLowStock } from '@/api/device'
export default {
  name: "Shipment",
  components: {
    DeviceBlock,
    FunctionsGrid
  },
  mounted() {
    this.fetch()
  },
  data() {
    return {
      functions: [
        {
          id: 1,
          icon: require('@/assets/device/icon_shipment.png'),
          name: this.$t('bu-huo'),
          path: '/device/shipmenInventorytList?shipment=true',
          target: this.functionClick
        },
        {
          id: 2,
          icon: require('@/assets/device/icon_pandian.png'),
          name: this.$t('pan-dian'),
          path: '/device/shipmenInventorytList?shipment=false',
          target: this.functionClick
        },
        {
          id: 3,
          icon: require('@/assets/device/icon_shipment_record.png'),
          name: this.$t('bu-huo-ji-lu'),
          path: '/device/shipmenInventorytHistory?shipment=true',
          target: this.functionClick
        },
        {
          id: 4,
          icon: require('@/assets/device/icon_document.png'),
          name: this.$t('pan-dian-ji-lu'),
          path: '/device/shipmenInventorytHistory?shipment=false',
          target: this.functionClick
        },
      ],
      lowStockDeviceCount: {
        productDeviceCount: 0,
        productSourceCount: 0
      }
    };
  },
  methods: {
    async fetch() {
      const { data: lowStockDeviceCount } = await deviceCountOfLowStock()
      this.lowStockDeviceCount = lowStockDeviceCount
    },
    functionClick(source) {
      this.$router.push(source.path)
    }
  }
};
</script>

<style lang="less" scoped>
.wrapper {
  background: @main-container-bk-color;
  padding: 0 16px;
  .block-wrapper {
    padding-top: 12px;
    justify-content: space-between;
    background: @main-container-bk-color;
    display: flex;

    align-items: center;
  }
  .title {
    padding-top: 24px;
 
    font-size: 18px;
    font-weight: bold;
    color: @color-black;
  }
 
}
</style>
