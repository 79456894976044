import request from '@/utils/request'

// 查询设备列表
export function getRefill(deviceId) {
  return request({
    url: '/mobile/refill/' + deviceId,
    method: 'get'
  })
}

export function getCheck(deviceId) {
  return request({
    url: '/mobile/check/' + deviceId,
    method: 'get'
  })
}

export function saveRefill(data) {
  return request({
    url: '/mobile/refill',
    method: 'post',
    data
  })
}

export function saveCheck(data) {
  return request({
    url: '/mobile/check',
    method: 'post',
    data
  })
}


// 解锁 / 锁定货道
export function updateRailStatus(railId, status) {
  return request({
    url: `/operator/deviceRail/salesStatus/${railId}/${status}`,
    method: 'put'
  })
}


export function refillList(params) {
  return request({
    url: '/mobile/refill/list',
    method: 'get',
    params
  })
}

export function checkList(params) {
  return request({
    url: '/mobile/check/list',
    method: 'get',
    params
  })
}

export function refillDto(id) {
  return request({
    url: `/mobile/refill/dto/${id}`,
    method: 'get'
  })
}

export function checkDto(id) {
  return request({
    url: `/mobile/check/dto/${id}`,
    method: 'get'
  })
}