<template>

    <van-pull-refresh
            v-model="isPullLoading"
            :disabled="disabled"
            @refresh="onRefresh">
        <div ref="warrper" style="overflow: auto" :style="{height:wrapperHeight}">
            <van-list
                    v-model="isListLoading"
                    :finished="finished"
                    @load="onload"
                    :error.sync="error"
                    :finished-text="finishedText"
                    error-text="请求失败，点击重新加载">
                <slot/>
            </van-list>
        </div>
    </van-pull-refresh>

</template>

<script>
    export default {
        name: "ListPage",
        props: {
            page: {
                type: Number,
                default: 1
            },
        },
        data() {
            return {
                finishedText: "没有更多加载",
                isPullLoading: false,
                isListLoading: false,
                isDataLoading: false,
                list: [],
                error: false,
                finished: false,
                total: 99999,
                limit: 20,
                isLoading: false,
                disabled: false,
                wrapperHeight: 640 + 'px',
                scrollListener: () => {
                    let scrollTop = this.$refs.warrper.scrollTop;
                    this.disabled = scrollTop !== 0;
                }
            }
        },
        mounted() {
            // console.log("this.$refs.warrper.getBoundingClientRect().y", this.$refs.warrper.getBoundingClientRect().y)
            this.wrapperHeight = `${document.body.offsetHeight - this.$refs.warrper.getBoundingClientRect().y}px`
            this.$refs.warrper.addEventListener("scroll", this.scrollListener)
        },
        beforeDestroy() {
            this.$refs.warrper.removeEventListener("scroll", this.scrollListener)
        },
        watch: {
            finished(newValue) {
                if (newValue) {
                    if (this.total === 0) {
                        this.finishedText = "数据为空"
                    } else {
                        this.finishedText = "没有更多加载"
                    }
                }
            }
        },
        methods: {
            loadData(promiss) {
                return new Promise((resolve) => {
                    promiss.then(data => {
                        this.isDataLoading = false;
                        if (data.code === 200) {
                            if (data.rows !== undefined) {
                                if (this.page === 1) {
                                    this.list = data.rows
                                } else {
                                    this.list.push(...data.rows)
                                }
                            } else {
                                this.finished = true
                                this.list = data.data
                            }
                            resolve(this.list)
                            this.total = data.total
                            this.setStatus()
                            if (!this.finished) {
                                this.$emit('update:page', this.page + 1)
                            }
                        } else {
                            this.error = true;
                            this.finished = false
                            this.isPullLoading = false
                            this.isListLoading = false
                        }
                    }).catch(() => {
                        this.isDataLoading = false;
                        this.error = true;
                    })
                })
            },
            setStatus() {
                this.error = false;
                this.isListLoading = false
                this.isPullLoading = false
                if (this.page * this.limit >= this.total) {
                    this.finished = true;
                }
            },
            onRefresh() {
                this.page = 1;
                this.finished = false
                this.isPullLoading = false
                this.isListLoading = false
                this.isDataLoading = true;
                this.$emit('update:page', 1)
                this.$emit('load')
            },
            onload() {
                if (this.isPullLoading || this.isDataLoading) {
                    return
                }
                this.isPullLoading = false
                this.isDataLoading = true;
                this.$emit('load')
            }
        },

    }
</script>

<style lang="less" scoped>

    .warrper111 {
        height: 400px;
    }

</style>
