export const pageMixing = {
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
    };
  },
  async mounted() {
    this.fetch()
  },
  methods: {
    async fetch() {
      this.beforFetch()
      const { rows, total } = await this.injectFetch()
      this.afterFetch(rows, total)
      this.pageFetchComplete()
    },
    beforFetch() {
      this.loading = true
      this.finished = false
    },
    afterFetch(rows, total) {
      this.list.push(...rows)
      this.loading = false;
      if (this.list.length >= total) {
        this.finished = true
      }
    },
    pageFetchComplete() {
      // 整个分页结束完后调用
    },
    onLoad() {
      this.queryParams.pageNum = this.queryParams.pageNum + 1
      this.fetch()
    }
  }
}