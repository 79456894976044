<template>
  <div class="buttom-tab-wrapper">
    <van-tabbar class="buttom-bar"  v-model="buttomTabActive"  :border="false" active-color="#6BC263" inactive-color="#949994">
      <van-tabbar-item to="/home" name="home" icon="home-o">{{$t('shou-ye')}}</van-tabbar-item>
      <van-tabbar-item to="/operate" name="operate" icon="apps-o">{{$t('gong-neng')}}</van-tabbar-item>
      <van-tabbar-item to="/my" name="my"  icon="friends-o">{{$t('wo-de')}}</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>

export default {
  name: "BottomTab",
  components: {},
  data() {
    return {
      buttomTabActive: 0,
    };
  },
  mounted() {
    this.onRouteChange()
  },
  methods: {
    onRouteChange() {
      this.buttomTabActive = this.$route.name.toLowerCase()
    }
  }
};
</script>

<style lang="less" scoped>
.buttom-tab-wrapper {
  .buttom-bar {
     border-top: 4px solid @main-container-bk-color;
  }
 
}
</style>
