<template>
  <card :class="large? 'card' : 'card csmall'" :style="{background: selected? '#6BC263': ''}">
    <div :class="large? 'inner' : 'inner ismall'">
      <div v-if="selected" class="common-device-line-white"></div>
      <div v-else>
        <div v-if="type === 'stop'" class="common-device-line-grey"></div>
        <div v-if="type === 'online'" class="common-device-line-green"></div>
        <div v-if="type === 'error'" class="common-device-line-red"></div>
        <div v-if="type === 'shipment'" class="common-device-line-yellow"></div>
        <div v-if="type === 'shipmentProduct'" class="common-device-line-yellow"></div>
      </div>

      <div :class="large? 'title': 'title tsmall'" :style="{color: selected? '#FFFFFF': ''}">
        {{ title }}
      </div>
      <div :class="large? 'desc' : 'desc dsmall'">
        <div v-if=" number != undefined " class="prefix">
           
          <span class="online-prefix" v-if="large && type === 'online'" :style="{color: selected? '#FFFFFF': ''}">{{ number }}</span>
          <span v-else :style="{color: selected? '#FFFFFF': ''}" >{{ number }}</span>

          <span v-if="large" class="unit" :style="{color: selected? '#FFFFFF': ''}">台</span>
        </div>
        <div v-else>
          <van-loading :size="large? 24 : 16" type="spinner" />
        </div>
        <div class="surfix" v-if="large">
          <div><i class="iconfont" :class="icon" :style="{color: iconColor}"/></div>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import Card from './Card'
export default {
  name: 'DeviceBlock',
  props: {
    large: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      // error stop  online
      default: 'online'
    },
    number: {
      type: Number,
      default: undefined,
    },
    title: {
      type: String,
      default: '在线设备'
    },
    selected: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'icon-sign'
    },
    iconColor: {
      type: String,
      default: '#C8CCC8'
    },
  },
  components: {
    Card
  }
}
</script>

 
<style lang="less" scoped >
.card {
  padding: 0px;
  width: 165px;
  height: 98px;
  .inner {
    margin: 16px 8px 0 16px;
    .title {
      margin-top: 8px;
      margin-bottom: 16px;
      color: @color-grey;
      font-size: 14px;
    }
    
    .tsmall {
      margin-top: 4px;
    }
    .desc {
      display: flex;
      justify-content: space-between;
      font-weight: bold;
      color: @color-black;
      font-size: 24px;
      .online-prefix {
        color: @main-color;
      }
      .unit {
        margin-left: 4px;
        font-size: 11px;
        color: @color-grey;
      }
      .surfix {
        .iconfont {
          font-size: 32px;
        }
      }
    }
    .dsmall {
      font-size: 18px;
    }
  }
  .ismall {
    margin: 12px 0px 0 12px;
  }
}
.csmall {
  width: 80px;
  height: 78px;
}
</style>
