<template>
  <div class="wrapper">
 

    <Card class="msg" v-if="false">
      <Filed @click="aboutUs" title="修改密码" />
      <Filed @click="clickName" title="修改用户名" />
      <Filed @click="clickPhone" title="修改手机号" />
      
    </Card>

    <Card class="msg" >
      <Filed @click="aboutUs" title="返回" />
    </Card>
    
    <van-button @click="logout" class="logout" type="danger">{{$t('tui-chu-deng-lu')}}</van-button>
   
  </div>
</template>

<script>
import Card from '@/components/Card'
import Filed from '@/components/Filed'
import { mapActions } from 'vuex';
export default {
  name: "AboutUs",
  components: {
    Card,
    Filed
  },
  data() {
    return {
    };
  },
  methods: {
    ...mapActions(('user'), [
      'LogOut'
    ]),
    aboutUs() {
      this.$router.go(-1)
    },
    clickName() {
    },
    clickPhone() {
    },
    async logout() {
      this.$dao.local.del('juicePassword')
      await this.LogOut()
      
      location.reload()
    }
  }
};
</script>

<style lang="less" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 
  .msg {
    margin-top: 8px;
    width: 343px;
  }
  .logout {
    margin-top: 12px;
    width: 343px;
    background: @color-red;
  }
}

</style>
