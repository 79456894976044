<template>
  <div class="wrapper">
    <div class="bar">
      <van-sidebar v-model="layer">
        <van-sidebar-item v-for="item in layers" :key="item.railRow" :title="item.name" />
      </van-sidebar>
    </div>
    <div class="stocks">
      <div class="item-wrapper" >
        <div class="item"  v-for="(s, index) in stocks.get(layer + 1)" :key="s.railId" >
          <div @click="clickStock(s)" class="lock-mask" v-if="!s.salesStatus">
            <img src="@/assets/device/icon_lock.png" alt="">
          </div>
          <div @click="clickStock(s)" class="stock-config-img product-img">
            <div class="stock-name">
              {{ `${layer + 1}-${index + 1}` }}
            </div>
            
    
            <img v-if="s.productName" :src="s.smallImg" alt="">
            
          </div>
          <div class="name">
            <div class="name">{{ s.productName? s.productName : $t('zan-wei-pei-zhi')}}</div>
          </div>
          <div class="num">
            {{$t('yuan-you-ku-cun')}}：{{ s.railStockBefore }}
          </div>
          <van-stepper min="0" :max="s.productName? s.railCapacity : 0" v-model="s.railStockAfter" />
        </div>
      </div>
    </div>
    <BottomBar @onClickOne="cancle" @onClickTwo="submit"/>
     

    <van-overlay class="juice-screen-loading" :show="loading" >
      <van-loading style="color: #ffffff" size="40px" vertical><span style="color: #ffffff">{{$t('zheng-zai-zhun-bei-shu-ju')}}...</span></van-loading>
    </van-overlay>
    
    <TipsDialog :config="tipConfig"/>
  </div>
</template>

<script>
import BottomBar from '@/components/BottomBar'
import TipsDialog from '@/components/TipsDialog'
import { getCheck, saveCheck, updateRailStatus} from '@/api/refill'
import utils from '@/utils'
import { refillMixing } from '@/mixins/refill'

export default {
  name: "InventoryStock",
  mixins: [ refillMixing ],
  components: {
    BottomBar,
    TipsDialog
  },
  mounted() {
    this.fetch()
  },
  data() {
    return {
      layer: 1 - 1,
      layers: [],
      loading: false,
      stocks: new Map(),
      tipConfig: {
        type: 'inventory',
        title: this.$t('pan-dian-ti-shi'),
        tips: [
          this.$t('zuo-ce-xuan-ze-ceng-shu'),
          this.$t('pan-dian-yun-xu-jian-shao-shu-liang'),
          this.$t('dian-ji-shang-pin-tu-pian-ke-yi-suo-ding-huo-zhe-jie-suo-huo-dao'),
          this.$t('dian-ji-shu-liang-ke-yi-zhi-jie-shu-ru'),
        ]
      }
    };
  },
  methods: {
    async fetch() {
      this.loading = true
      const { data } = await getCheck(this.deviceId)
      this.serverData = data

      this.layers = []
      this.stocks.clear

      this.serverData.checkRailDtoList.forEach(item => {
        const railRow = item.railRow
        if (!this.stocks.get(railRow)) {
          this.layers.push({
            name : `第${utils.numberToChinese(railRow)}层`,
            railRow
          })
          this.stocks.set(railRow, new Array())
        }
        let column = this.stocks.get(railRow)
        column.push(item)
      });
      this.loading = false
    },
    async submit() {
      this.loading = true
      try {
        await saveCheck(this.serverData)
        this.pushStatusPage(true)
      } catch(e) {
        this.pushStatusPage(true, false)
      }
      this.loading = false
    },
    clickStock(item) {
      this.$dialog.confirm({
        title: `${item.salesStatus? this.$t('suo-ding') : this.$t('jie-suo')}${ this.$t('ti-shi') }`,
        message: this.$t('que-ding-yao') + `${item.salesStatus? this.$t('suo-ding') : this.$t('jie-suo')}` + this.$t('ci-huo-dao-ma') + '？',
      })
      .then(async () => {
        // on confirm
        try {
          await updateRailStatus(item.deviceRailId, !item.salesStatus)
          item.salesStatus = !item.salesStatus
        } catch(e) {
          console.log(e)
        }
      })
    }
  }
};
</script>

<style lang="less" scoped>
.wrapper {
  min-height: 100vh;
  background: white;
  display: flex;
  .bar {
    width: 70px;
    /deep/ .van-sidebar-item--select:before {
      background-color: @main-color;
    }
    /deep/ .van-sidebar-item {
      background: white;
      .van-sidebar-item__text {
        color: @color-grey;
        font-weight: bold;
      }
    }
    /deep/ .van-sidebar-item--select {
      .van-sidebar-item__text {
        color: @color-black;
        font-weight: bold;
      }
    }
    /deep/ .van-sidebar {
      border-right: 1px solid #EBEDEB;
      height: 100vh;
      width: 70px;
    }
  }
  .stocks {
    flex-grow: 1;
    .item-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 33%);
      grid-row-gap: 24px;
      margin-bottom: 80px;
      .item {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 12px 0;
        .lock-mask {
          position: absolute;
          top: 0;
          background: #7b7b7bbb;
          width: 81px;
          height: 81px;
          z-index: 999;
          img {
            width: 80px;
            height: 80px;
          }
        }
        .product-img {
          position: relative;
          text-align: center;
          margin-right: 0;
          .stock-name {
            position: absolute;
            color: white;
            font-size: 11px;
            background: @main-color;
            width: fit-content;
            text-align: center;
            line-height: 16px;
            border-radius: 2px;
            width: 29px;
            height: 16px;
            left: 0;
            top: 0;
          }
          
        }
        .name {
          color: @color-black;
          font-size: 14px;
          margin-top: 12px;
        }
        .num {
          margin-top: 4px;
          margin-bottom: 12px;
          color: @color-grey;
          font-size: 12px;
        }
      }
    }

  }

}
</style>
