<template>
  <div class="wrapper">
    <div class="top-wrapper">
      <div class="top">
        <div class="title">
          {{ device.deviceName }}
        </div>
        <div class="address">
          <img src="@/assets/device/icon_address.png" alt=""> {{ device.addressInfo? device.addressInfo :
          $t('di-zhi-xin-xi-wei-wan-shan')}}
        </div>
        <div class="tags">
          <div class="tag">
            {{$t('guo-cha-ji')}}
          </div>
        </div>

        <i class="iconfont top-icon icon-device" name="icon-device"></i>

      </div>
      <div class="center-block">
        <div class="item">
          <div class="icon">
            <Icon name="icon-device" size="1.06rem" :color="!device.errorStatus? '#6BC263' : '#FF7C6B'"/>
          </div>
          <div class="name">{{$t('she-bei-zhuang-tai')}}</div>
        </div>
        <div class="item">
          <div class="icon">
            <Icon name="icon-sign" size="1.06rem" :color="device.onlineStatus? '#6BC263' : '#C8CCC8'"/>
          </div>
          <div class="name">{{$t('wang-luo-zhuang-tai')}}</div>
        </div>
        <div class="item">
          <div class="icon">
            <Icon name="icon-stock" size="1.06rem" :color="device.productStatus? '#6BC263' : '#FFC96B'"/>
          </div>
          <div class="name">{{$t('shang-pin-zhuang-tai')}}</div>
        </div>
      </div>
    </div>
    <Card class="center">
      <div class="title">
        {{$t('she-bei-xin-xi')}}
      </div>
      <div class="filed">
        <div class="key">{{$t('she-bei-ming-cheng')}}：</div>
        <div class="value">{{device.deviceName}}</div>
      </div>
      <div class="filed">
        <div class="key">{{$t('she-bei-bian-hao')}}：</div>
        <div class="value">{{device.deviceCode}}</div>
      </div>
      <div class="filed">
        <div class="key">{{$t('she-bei-lei-xing')}}：</div>
        <div class="value">{{$t('guo-cha-ji')}}</div>
      </div>
      <div class="filed">
        <div class="key">{{$t('she-bei-di-zhi')}}：</div>
        <div class="value"> {{ device.addressInfo? device.addressInfo : $t('di-zhi-xin-xi-wei-wan-shan')}}</div>
      </div>
      <div class="filed">
        <div class="key">{{$t('she-bei-wen-du')}}：</div>
        <div class="value"> {{ temperatureShow }}</div>
      </div>

      <div class="filed">
        <div class="key">杯盖库存：</div>
        <div class="value"> {{ device.cupCoverStock }}</div>
      </div>
      <div class="filed">
        <div class="key">一号水桶：</div>
        <div class="value">
          <div class="green-status" v-if="waters[0]"></div>
          <div class="red-status" v-else></div>
        </div>
      </div>
      <div class="filed">
        <div class="key">二号水桶：</div>
        <div class="value">
          <div class="green-status" v-if="waters[1]"></div>
          <div class="red-status" v-else></div>
        </div>
      </div>
      <div class="filed">
        <div class="key">三号水桶：</div>
      <div class="value">
          <div class="green-status" v-if="waters[2]"></div>
          <div class="red-status" v-else></div>
      </div>
    </div>

    </Card>

    <Card class="stock-center">
      <div class="title">
        果酱库存
      </div>
      <div v-for="item in device.devicePumpDtoList" :key="item.id">
        <div class="filed">
          <div class="key">泵_{{item.pumpCode}}：</div>
          <div v-if="item.jamName" class="value">{{item.jamName}} - {{item.jamStock}} ml</div>
          <div v-else class="value">空 - {{item.jamStock}} ml</div>
        </div>
      </div>
    </Card>

    <Card class="stock-center">
      <div class="title">
        冰杯库存
      </div>
      <div v-for="item in device.deviceRailDtoList" :key="item.id">
        <div class="filed">
          <div class="key">货道{{item.railRow}}-{{item.railCol}}：</div>
          <div v-if="item.productName" class="value">{{ item.productName }} - {{ item.railStock }} </div>
          <div v-else class="value">空 - {{ item.railStock }} </div>
        </div>
      </div>
    </Card>
 
    <div style="height: 80px">
    </div>
    <div class="bottom">
      <div class="shipment big-button" @click="toShipment">{{$t('bu-huo')}}</div>
      <div class="check big-button" @click="toInventory">{{$t('pan-dian')}}</div>
      <div class="other">
        <div class="button" @click="clickOperator">
          <div class="button-icon">
            <div class="icon-img door" alt=""/>
          </div>
          <div class="name">
            运维
          </div>
        </div>
        <div class="button" @click="clickScreenshot">
          <div class="button-icon">
            <div class="icon-img" alt=""/>
          </div>
          <div class="name">
            {{$t('jie-ping')}}
          </div>
        </div>
      </div>
    </div>

    <van-dialog class="img-dialog" v-model="img.dialog" :title="$t('tu-pian-cha-kan')" show-cancel-button>

      <div class="inner">
        <van-image :src="img.data">
          <template v-slot:loading>
            <van-loading type="spinner" size="20"/>
          </template>
        </van-image>
      </div>
    </van-dialog>


    <van-dialog v-model="operator.dialog" class="operator-dialog" title="机器运维二维码" :showConfirmButton="false"
                :closeOnClickOverlay="true">
      <van-image :src="operator.data" class="operator-qrcode">
        <template v-slot:loading>
          <van-loading type="spinner" size="20"/>
        </template>
      </van-image>

      <div class="operator-msg" style="color: #63C292">5分钟有效</div>
      <div class="operator-msg">打开密码界面后进行反向扫码</div>

    </van-dialog>
  </div>
</template>

<script>

  import {getDevice, screenshot, takePhoto, temperature, water, operationDialog} from '@/api/device'
  import Card from '@/components/Card'
  import Icon from '@/components/Icon'

  export default {
    name: "ReplenishmentPump",
    components: {
      Card,
      Icon
    },
    created() {
      this.deviceId = this.$route.query.deviceId
      this.fetch()
    },
    computed: {
      temperatureShow() {
        if (!this.temperature) {
          return '未知'
        } else {
          return `箱内: ${this.temperature.boxTemp}°C | 热水箱: ${this.temperature.hotWaterTemp}°C | 寻址机: ${this.temperature.machineTemp}°C`
        }
      }
    },
    data() {
      return {
        device: {},
        deviceId: undefined,
        img: {
          dialog: false,
          data: undefined
        },
        temperature: null,
        operator: {
          dialog: false,
          data: undefined
        },
        waters: []
      };
    },
    methods: {
      async fetch() {
        const {data} = await getDevice(this.deviceId)
        this.device = data
        try {
          const { data: temperatureData } = await temperature(this.device.deviceCode)
          this.temperature = temperatureData
          const { data: waterData } = await water(this.device.deviceCode)
          const waters = waterData.split('-').map(item => {
            return item === 'true'
          })
          this.waters = waters
          this.temperature = temperatureData
        } catch (e) {
          console.log(e)
        }
      },
      onSearch(params) {
        console.log(params)
      },
      toShipment() {
        this.$router.push({
          path: '/device/shipmenInventorytList?shipment=true',
          query: {deviceCode: this.device.deviceCode}
        })
      },
      toInventory() {
        this.$router.push({
          path: '/device/shipmenInventorytList?shipment=false',
          query: {deviceCode: this.device.deviceCode}
        })
      },
      async clickScreenshot() {
        this.img.dialog = true
        this.img.data = undefined
        try {
          const {data} = await screenshot(this.device.deviceCode)
          this.img.data = data
        } catch (error) {
          console.log(error)
          this.img.dialog = false
        }

      },
      async clickPhoto() {
        this.img.dialog = true
        this.img.data = undefined
        try {
          const {data} = await takePhoto(this.device.deviceCode)
          this.img.data = data
        } catch (error) {
          console.log(error)
          this.img.dialog = false
        }

      },
      async clickOperator() {
        this.operator.dialog = true
        const {data} = await operationDialog(this.device.deviceCode)
        this.operator.data = data
      }
    }
  };
</script>

<style lang="less" scoped>
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .top-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      .top {
        background: @main-color;
        width: 100vw;
        height: 158px;
        color: white;

        .title {
          margin-left: 16px;
          margin-top: 20px;
          font-size: 18px;
          font-weight: bold;
        }

        .address {
          margin-left: 16px;
          margin-top: 8px;
          display: flex;
          color: #D6FFD2;
          font-size: 12px;
          align-items: center;

          img {
            width: 12px;
            height: 12px;
          }
        }

        .tags {
          margin-left: 16px;
          margin-top: 8px;
          margin-bottom: 8px;
          display: flex;

          .tag {
            padding: 4px 10px;
            border: 1px solid #FFFFFF;
            font-size: 11px;
          }
        }

        .top-icon {
          position: absolute;
          top: 27px;
          right: 20px;
          font-size: 93px;
          color: #7AD571;
        }
      }

      .center-block {
        position: absolute;
        top: 113px;
        width: 343px;
        height: 113px;
        background: url('../../assets/device/status_bk.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: top;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;

        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #949994;
          font-size: 12px;
          margin-top: 12px;

          img {

            width: 40px;
            height: 40px;
          }
        }
      }
    }

    .center {
      width: 343px;
      margin-top: 68px;
      color: @color-black;

      .title {
        margin: 16px 0 24px 16px;
        font-size: 18px;
        font-weight: bold;
      }

      .filed {
        margin-left: 20px;
        margin-bottom: 16px;
        font-size: 16px;
        display: flex;

        .key {
          width: 85px;
        }

        .value {
          width: 223px;
          color: @color-grey;
          display: flex;
          align-items: center;
 
          .green-status {
            background: #6BC263;
            width: 10px;
            height: 10px;
            border-radius: 100%;
          }
          .red-status {
            background: #f0121d;
            width: 10px;
            height: 10px;
            border-radius: 100%;
          }
        }
      }
    }

    .stock-center {
      width: 343px;
      margin-top: 18px;
      color: @color-black;

      .title {
        margin: 16px 0 24px 16px;
        font-size: 18px;
        font-weight: bold;
      }

      .filed {
        margin-left: 20px;
        margin-bottom: 16px;
        font-size: 16px;
        display: flex;

        .key {
          width: 85px;
        }

        .value {
          width: 223px;
          color: @color-grey;
        }
      }
    }

    .bottom {
      height: 56px;
      background: white;
      width: 100vw;
      position: fixed;
      bottom: 0;
      display: flex;
      flex-direction: row-reverse;

      .other {
        display: flex;

        .button {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-right: 32px;

          .button-icon {
            .icon-img {
              width: 24px;
              height: 24px;
              background: url(../../assets/device/icon_screen.png);
              background-size: cover;
            }

            .door {
              background: url(../../assets/device/icon_door.png);
              background-size: cover;
            }
          }

          .name {
            margin-top: 4px;
            color: @color-grey;
            font-size: 12px;
          }
        }
      }

      .big-button {
        width: 112px;
        background: #EBFFE9;
        font-size: 16px;
        line-height: 56px;
        text-align: center;
        color: @main-color;
      }

      .shipment {
        color: #EBFFE9;
        background: @main-color;
      }
    }

    .img-dialog {
      .inner {
        padding: 10px 0;
      }

      text-align: center;
    }
  }

  .operator-dialog {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .operator-qrcode {
      display: flex;
      align-items: center;
      justify-items: center;
      text-align: center;

      img {
        width: 152px;
        height: 152px;
      }
    }

    .operator-msg {
      font-size: 14px;
      color: #626662;
      padding-bottom: 16px;
      text-align: center;
    }
  }


</style>
