<template>
  <div class="filed">
    <div class="prefix">
      <div class="name">{{ name }}</div>
      <div class="surplus">{{ desc }}</div>
    </div>
    <div class="surfix">
      <div class="handle"><slot></slot></div>
      <van-button v-if="buttonShow" @click="clickFull" color="#6BC263" class="button" size="small" plain type="primary">{{this.$t('bu-man')}}</van-button>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'Filed',
  props: {
    name: {
      type: String,
      // default: this.$t('shui') + '(ML)'
    },
    desc: {
      type: String,
      // default: this.$t('mu-qian-sheng-yu')
    },
    typeOrIndex: {
      type: String,
      default: 'water'
    },
    buttonShow: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    clickFull() {
      this.$emit('onClickFull', this.typeOrIndex)
    }
  }
}
</script>

 
<style  lang="less" scoped >
.filed {
  padding-top: 24px;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  .prefix {
    .name {
      font-size: 16px;
      color: @color-black;
    }
    .surplus {
      padding-top: 3px;
      color: @color-grey;
      font-size: 12px;
    }
  }
  .surfix {
    padding-bottom: 3px;
    margin-top: 3px;
    display: flex;
    .handle {  
      /deep/ .van-stepper__input {
        background: white !important;
      }
      /deep/ button {
        width: 30px !important;
        height: 30px !important;
      }
    }
    .button {
      margin-left: 17px;
      width: 60px;
      border-radius: 4px;
    }
  }
  
}
</style>
