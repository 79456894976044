<template>
  <div>
    <FunctionsGrid :functions="functions"/>
    <BottomTab />
  </div>
</template>

<script>
import BottomTab from './components/BottomTab'
import FunctionsGrid from '@/components/FunctionsGrid'
export default {
  name: "Operate",
  components: {
    BottomTab,
    FunctionsGrid
  },
  data() {
    return {
      functions: [
        {
          id: 1,
          icon: require('@/assets/home/icon_device.png'),
          name: this.$t('she-bei-zhuang-tai'),
          target: this.functionClicks,
          path: 'device'
        },
        {
          id: 2,
          icon: require('@/assets/home/icon_order.png'),
          name: this.$t('ding-dan-guan-li'),
          target: this.functionClicks,
          path: 'order'
        },
        {
          id: 3,
          icon: require('@/assets/home/icon_shipment.png'),
          name: this.$t('bu-huo'),
          path: 'device/shipment/index',
          target: this.functionClicks
        },
        {
          id: 4,
          icon: require('@/assets/home/icon_customer_vip_juice.png'),
          name: '会员点单',
          path: 'device/order/errand',
          target: this.functionClicks
        },
        {
          id: 5,
          icon: require('@/assets/home/icon_customer_juice.png'),
          name: '客户点单',
          path: 'device/order/customerOrder',
          target: this.functionClicks
        },
      ]
    };
  },
  computed: {},
  methods: {
    functionClicks(source) {
      this.$router.push(source.path)
    }
  }
};
</script>

<style lang="less" scoped>


</style>
