import { login, logout, getInfo } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'

const state = () => ({
  token: getToken(),
  name: '',
  avatar: '',
  roles: [],
  permissions: []
})


const getters = {}

const actions = {
  Login({ commit }, userInfo) {
    removeToken();
    return new Promise((resolve, reject) => {
      login(userInfo).then(res => {
        setToken(res.token)
        commit('setToken', res.token)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  GetInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token).then(res => {
        const user = res.user
        const avatar = user.avatar == "" ? require("@/assets/avatar.png") : user.avatar;
        if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
          commit('setRoles', res.roles)
          commit('setPermissions', res.permissions)
        } else {
          commit('setRoles', ['ROLE_DEFAULT'])
        }
        commit('setName', user.userName)
        commit('setAvatar', avatar)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  LogOut({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        commit('setToken', '')
        commit('setRoles', [])
        commit('setPermissions', [])
        removeToken()
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
}

const mutations = {
  setPermissions(state, permissions) {
    state.permissions = permissions
  },
  setRoles(state, roles) {
    state.roles = roles
  },
  setInfo(state, info) {
    state.info = info
  },
  setToken(state, token) {
    state.token = token
  },
  setName(state, name) {
    state.name = name
  },
  setAvatar(state, avatar) {
    state.avatar = avatar
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}