<template>

  <div class="top-wrapper">
    <slot name="header"></slot>
    <div class="detail-card-wrapper">
      <div class="detail-card">
        <slot name="main"></slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "HomeTopCard",
};
</script>

<style lang="less" scoped>
.top-wrapper {
  background-image: url('../../../assets/bk_green.png');
  background-size: 100% 62%;
  background-repeat: no-repeat;
  background-position: top;
  .detail-card-wrapper {
    width: 100%;
    color: #FFFFFF;
    .detail-card {
      width: 100%;
      height: 226px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: top;
      background-image: url('../../../assets/home/detail_bk.png');
      background-size: 100% auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      .top {
        font-size: 12px;
        margin-top: 32px;
        color: @color-grey;
      }
      .center {
        position: relative;
        width: 100%;
        text-align: center;
        margin-top: 12px;
        color: @main-color;
        font-size: 32px;
        .percent {
          position: absolute;
          font-size: 12px;
          color: @color-red;
          top: 8px;
          right: 100px;
        }
      }
      .bottom {
        width: 100%;
        display: flex;
        justify-content: space-around;
        .item {
          text-align: center;
          .number {
            color: @color-black;
            font-size: 24px;
            font-weight: bold;
          }
          .desc {
            color: @color-grey;
            font-size: 12px;
          }
        }
      }
    } 
  }
}
</style>