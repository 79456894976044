<template>
  <div class="wrapper">
    <van-tabs :border="false" title-inactive-color="#949994" title-active-color="#313331" color="#6BC263"   v-model="layer" >
      <van-tab :title="item.name" v-for="item in layers" :key="item.railRow">
      </van-tab>
    </van-tabs>

    <div class="item-wrapper">
      <div class="item" v-for="(s, index) in stocks.get(layer + 1)" :key="s.railId">
        <div class="inner">
          <div class="stock-config-img">
            <img v-if="s.productName" :src="s.smallImg" alt="">
          </div>
          <div class="desc">
            <div class="prefix">
              <div class="name">{{ s.productName? s.productName : $t('zan-wei-pei-zhi')}}</div>
              <div class="stock-msg">{{$t('huo-dao-rong-liang')}}: {{s.railCapacity}}</div>
              <div class="handle">
                <van-stepper integer  :min="s.productStock" :max="s.productName? s.railCapacity : 0" v-model="s.$stock" />
              </div>
            </div>
            <div class="surfix">
              <div class="stock-name">{{ `${layer + 1}-${index + 1}` }}</div>
              <div class="more" v-if="false"><img src="@/assets/device/icon_more.png" alt=""></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <BottomBar @onClickOne="cancle" @onClickTwo="submit"/>

    <van-overlay class="juice-screen-loading" :show="loading" >
      <van-loading style="color: #ffffff" size="40px" vertical><span style="color: #ffffff">{{$t('zheng-zai-zhun-bei-shu-ju')}}...</span></van-loading>
    </van-overlay>
  
    <TipsDialog :config="tipConfig"/>

  </div>
</template>

<script>
import BottomBar from '@/components/BottomBar'
import TipsDialog from '@/components/TipsDialog'
import { getRefill, saveRefill } from '@/api/refill'
import utils from '@/utils'
import { refillMixing } from '@/mixins/refill'

export default {
  name: "ShipmentStock",
  mixins: [ refillMixing ],
  components: {
    BottomBar,
    TipsDialog
  },
  mounted() {
    this.fetch()
  },
  data() {
    return {
      layer: 1 - 1,
      layers: [],
      stocks: new Map(),
      tipConfig: {
        type: 'shipment',
        title: this.$t('bu-huo-ti-shi'),
        tips: [
          this.$t('dian-ji-shang-fang-tab-ke-qie-huan-ceng-shu'),
          this.$t('bu-huo-zhi-yun-xu-zeng-jia-shu-liang-ru-xu-zhi-hang-jian-shao-cao-zuo-qing-xuan-ze-pan-dian'),
          this.$t('dian-ji-shu-liang-ke-yi-zhi-jie-shu-ru'),
        ]
      }
    }
  },
  methods: {
    async fetch() {
      this.loading = true
      const { data } = await getRefill(this.deviceId)
      this.serverData = data

      this.layers = []
      this.stocks.clear

      this.serverData.refillRailDtoList.forEach(item => {
        const railRow = item.railRow
        item.$stock = item.productStock
        if (!this.stocks.get(railRow)) {
          this.layers.push({
            name :  this.$t('di') + `${utils.numberToChinese(railRow)}` + this.$t('ceng'),
            railRow
          })
          this.stocks.set(railRow, new Array())
        }
        let column = this.stocks.get(railRow)
        column.push(item)
      });
      this.loading = false
    },
    async submit() {
      this.loading = true
      this.stocks.forEach(stock => {
        stock.forEach(data => {
          data.productQty = data.$stock - data.productStock
        })
      })
      try {
        await saveRefill(this.serverData)
        this.pushStatusPage(true)
      } catch(e) {
        this.pushStatusPage(false)
      }
      this.loading = false
    },
  }

};
</script>

<style lang="less" scoped>
.wrapper {
  background: @main-container-bk-color; 
  .item-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
    .item {
      margin-top: 4px;
      background: white;
      border-radius: 4px;
      width: 343px;
      .inner {
        margin: 16px;
        display: flex;

        .desc {
          flex-grow: 1;
          display: flex;
          justify-content: space-between;
          .prefix {
            .name {
              font-size: 16px;
              color: @color-black;
            }
            .stock-msg {
              margin-top: 8px;
              color: @color-grey;
              font-size: 12px;
            }
            .handle {
              margin-top: 24px;
              /deep/ .van-stepper__input {
                width: 50px !important;
                background: white !important;
              }
              /deep/ button {
                width: 30px !important;
                height: 30px !important;
              }
            }
          }
          .surfix {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .stock-name {
              color: @main-color;
              font-size: 14px;
            }
            .more {
              width: 16px;
              height: 16px;
              margin-bottom: 23px;
              img {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }
    }
  }

}
</style>
