import Status from '@/page/device/Status'
import Device from '@/page/device/Index'
import ReplenishmentPump from '@/page/device/shipment/ReplenishmentPump'
import ShipmentIndex from '@/page/device/shipment/Index'
import ShipmenInventorytList from '@/page/device/shipment/ShipmenInventorytList'
import ShipmenInventorytHistory from '@/page/device/shipment/ShipmenInventorytHistory'
import ShipmentStock from '@/page/device/shipment/ShipmentStock'
import InventoryStock from '@/page/device/shipment/InventoryStock'
import ShipmentDesc from '@/page/device/shipment/ShipmentDesc'
import CustomerOrder from '@/page/device/order/CustomerOrder'
import Customer from '@/page/device/order/Customer'
import Confirm from '@/page/device/order/Confirm'
import Errand from '@/page/device/order/Errand'
import OrderSuccess from '@/page/device/order/OrderSuccess'
const path = '/device'

export default [
  {
    path: path,
    component: Device,
    meta: {
      title: '设备列表'
    }
  },
  {
    path: `${path}/status`,
    component: Status,
    meta: {
      title: '设备详情'
    }
  },
  {
    path:  `${path}/shipment/replenishmentPump`,
    component: ReplenishmentPump,
    meta: {
      title: '原料操作'
    }
  },
  {
    path:  `${path}/shipment/index`,
    component: ShipmentIndex,
    meta: {
      title: '补货盘点首页'
    }
  },
  {
    path:  `${path}/shipmenInventorytList`,
    component: ShipmenInventorytList,
    meta: {
      title: '补货盘点列表'
    }
  },
  {
    path:  `${path}/shipmenInventorytHistory`,
    component: ShipmenInventorytHistory,
    meta: {
      title: '补货盘点历史记录'
    }
  },
  {
    path:  `${path}/shipment/stock`,
    component: ShipmentStock,
    meta: {
      title: '补货'
    }
  },
  {
    path:  `${path}/shipment/desc`,
    component: ShipmentDesc,
    meta: {
      title: '补货盘点详情'
    }
  },
  {
    path:  `${path}/shipment/inventory`,
    component: InventoryStock,
    meta: {
      title: '盘点'
    }
  },
  {
    path:  `${path}/order/customerOrder`,
    component: CustomerOrder,
    meta: {
      title: '客户订单'
    }
  },
  {
    path:  `${path}/order/customer`,
    component: Customer,
    meta: {
      title: '客户点单'
    }
  },
  {
    path:  `${path}/order/customer/confirm`,
    component: Confirm,
    meta: {
      title: '确认订单'
    }
  },
  {
    path:  `${path}/order/customer/orderSuccess`,
    component: OrderSuccess,
    meta: {
      title: '下单成功'
    }
  },
  {
    path:  `${path}/order/errand`,
    component: Errand,
    meta: {
      title: '会员点单'
    }
  },
]