<template>
  <van-overlay class="dialog" :show="show" @click="show = false">
    <Card class="inner">
      <div class="title">{{ config.title }}</div>
      <div class="tips" v-for="(t, index) in config.tips" :key="index">
        <div class="prefix">{{ index + 1 }}:</div>
        <div class="surfix">
          {{ t }}
        </div>
      </div>
      <div class="btn">
        <van-button  type="primary" block @click="clickKnow">{{$t('wo-zhi-dao-le')}}</van-button>
        <div class="text-btn">{{$t('xia-ci-ji-xu-ti-xing-wo')}}</div>
      </div>
    </Card>
  </van-overlay>
</template>

<script>
import Card from './Card'
export default {
  name: 'TipsDialog',
  components: {
    Card
  },
  props: {
    config: {
      type: Object,
      default () {
        return {
          type: 'shipment',
          title: '补货提示',
          tips: [
            '1: 补货只允许增加数量，不允许减少',
            '1: 补货只允许增加数量，不允许减少',
            '1: 补货只允许增加数量，不允许减少',
          ]
        }
      }
    }
  },
  data() {
    return {
      show: false
    }
  },
  mounted() {
    const key = `tips:${ this.config.type }`
    // this.$dao.local.del(key)
    let value = this.$dao.local.get(key)
    if (!value) {
      this.show = true
    } else {
      this.show = false
    }

  },
  methods: {
    clickKnow() {
      const key = `tips:${ this.config.type }`
      this.$dao.local.set(key, 'know')
    }
  }
}
</script>

 
<style lang="less" scoped >
.dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 160px;
  .inner {
    padding: 24px 0;
    width: 343px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    .title {
      text-align: center;
      margin: 0px 32px 12px 32px;
      color: @color-black;
      font-size: 18px;
      font-weight: bold;
    }
    .tips {
      margin: 0px 32px 12px 32px;
      color: @color-grey;
      font-size: 16px;
      width: 279px;
      display: flex;
      align-items: baseline;
      .prefix {
        margin-right: 8px;
      }
    }
    .btn {
      margin-top: 12px;
      width: 279px;
      .text-btn {
        margin-top: 12px;
        text-align: center;
        font-size: 10px;
        color: @color-grey;
      }
    }
  }
}
</style>
