<template>
  <div class="card">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Card'
}
</script>

 
<style scoped >
.card {
  background: #FFFFFF;
  border-radius: 4px;
}
</style>
