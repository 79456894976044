<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>
<style lang="less">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: @main-container-bk-color;
  min-height: 100vh;
}
* {
  margin: 0px;
  padding: 0px;
}
</style>
