import request from '@/utils/request'

// 查询设备列表
export function listDevice(query) {
  return request({
    url: '/operator/device/listPageVo',
    method: 'get',
    params: query
  })
}
// 查询设备列表
export function listMyDevice(query) {
  return request({
    url: '/operator/device/listMyDevice',
    method: 'get',
    params: query
  })
}
// 查询设备详细
export function getDevice(id) {
  return request({
    url: '/operator/device/' + id,
    method: 'get'
  })
}


export function configSalesStatus(id) {
  return request({
    url: `/operator/device/salesStatus/${id}`,
    method: 'put'
  })
}

export function listRails(id) {
  return request({
    url: `/operator/deviceRail/list/${id}`,
    method: 'get'
  })
}

export function listPumps(id) {
  return request({
    url: `/operator/devicePump/list/${id}`,
    method: 'get'
  })
}

// 配置货道
export function configRail(data) {
  return request({
    url: '/operator/deviceRail/update',
    method: 'put',
    data
  })
}


export function configPump(data) {
  return request({
    url: '/operator/devicePump/update',
    method: 'put',
    data
  })
}

export function screenshot(id) {
  return request({
    url: '/operator/device/screenshot/' + id,
    method: 'get'
  })
}
export function takePhoto(id) {
  return request({
    url: '/operator/device/photo/' + id,
    method: 'get'
  })
}

export function deviceCountByType() {
  return request({
    url: '/operator/device/deviceCountByType',
    method: 'get'
  })
}
export function deviceCountOfLowStock() {
  return request({
    url: '/operator/device/deviceCountOfLowStock',
    method: 'get'
  })
}

export function getUserData() {
  return request({
    url: '/operator/device/getUserData',
    method: 'get'
  })
}


export function temperature(deviceCode) {
  return request({
    url: '/operator/device/temperature/' + deviceCode,
    method: 'get'
  })
}

export function water(deviceCode) {
  return request({
    url: '/operator/device/water/' + deviceCode,
    method: 'get'
  })
}

export function operationDialog(deviceCode) {
  return request({
    url: '/operator/device/operation/qrcode/' + deviceCode,
    method: 'get'
  })
}
// 查询设备商品
export function getDeviceProduct(deviceCode) {
  return request({
    url: '/operator/device/getProductList?deviceCode=' + deviceCode,
    method: 'get'
  })
}


export function getDeviceError(deviceCode) {
  return request({
    url: '/operator/device/getErrorList?deviceCode=' + deviceCode,
    method: 'get'
  })
}


export function getDevicePortInfo(deviceCode) {
  return request({
    url: '/operator/device/port/' + deviceCode,
    method: 'get'
  })
}
