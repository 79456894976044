<template>
  <div class="wrapper">
    <Card class="top">
      <div class="logo-wrapper">
        <img src="@/assets/logo.png" alt="">
      </div>
      <div class="name">果茶小店</div>
      <div class="version">Verison：1.0.0</div>
    </Card>

    <Card class="msg" v-if="false">
      <Filed @click="aboutUs" title="关于我们" />
      <Filed title="帮助和反馈" />
    </Card>

     <Card class="msg">
      <Filed @click="aboutUs" title="返回" />
    </Card>
    
    <div class="bottom">
      <div class="name">上海果之韵</div>
      <div class="remark">沪ICP备18000749号-2</div>
    </div>
  </div>
</template>

<script>
import Card from '@/components/Card'
import Filed from '@/components/Filed'
 
export default {
  name: "AboutUs",
  components: {
    Card,
    Filed
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    aboutUs() {
      this.$router.go(-1)
    }
  }
};
</script>

<style lang="less" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .top {
    margin-top: 5px;
    width: 343px;
    height: 269px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .logo-wrapper {
      width: 92px;
      height: 92px;
      margin-top: 59px;
      img {
        width: 92px;
        height: 92px;
      }
    }
    .name {
      color: @main-color;
      font-weight: bold;
      font-size: 18px;
      margin: 21px 0  5px 0;
    }
    .version {
      color: #C8CCC8;
      font-size: 14px;
    }
  }
  .msg {
    margin-top: 8px;
    width: 343px;
  }
  .bottom {
    position: fixed;
    bottom: 19px;
    color: @color-grey;
    font-size: 11px;
    text-align: center;
    .name {
      margin-bottom: 9px;
    }
  }
}

</style>
