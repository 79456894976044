<template>
  <div class="wrapper">
    <div class="name">
      <div class="icon"><van-icon name="cashier-o" /></div>
      <div>{{$t('she-bei-ming-cheng')}}：{{ deviceName }}</div>
    </div>
    <Card class="shment-wrapper">
      <div class="inner">
        <div class="title">{{$t('chang-gui-yuan-liao')}}</div>
        <Filed :name="$t('shui3')"
               :desc="`${$t('mu-qian-sheng-yu')}${isShipment? serverData.waterStock3 : serverData.waterStockBefore3}ML`"
               :buttonShow="isShipment"
               @onClickFull="clickFull"
               typeOrIndex="water3">
          <van-stepper v-if="isShipment" :min="serverData.waterStock3" max="18000" v-model="serverData.$water3" />
          <van-stepper v-else :min="0" max="18000" v-model="serverData.waterStockAfter3" />
        </Filed>
        <div class="line"></div>
        <Filed :name="$t('shui2')"
               :desc="`${$t('mu-qian-sheng-yu')}${isShipment? serverData.waterStock2 : serverData.waterStockBefore2}ML`"
               :buttonShow="isShipment"
               @onClickFull="clickFull"
               typeOrIndex="water2">
          <van-stepper v-if="isShipment" :min="serverData.waterStock2" max="18000" v-model="serverData.$water2" />
          <van-stepper v-else :min="0" max="18000" v-model="serverData.waterStockAfter2" />
        </Filed>
        <div class="line"></div>
        <Filed :name="$t('shui1')"
               :desc="`${$t('mu-qian-sheng-yu')}${isShipment? serverData.waterStock1 : serverData.waterStockBefore1}ML`"
               :buttonShow="isShipment"
               @onClickFull="clickFull"
               typeOrIndex="water1">
          <van-stepper v-if="isShipment" :min="serverData.waterStock1" max="18000" v-model="serverData.$water1" />
          <van-stepper v-else :min="0" max="18000" v-model="serverData.waterStockAfter1" />
        </Filed>
        <div class="line"></div>
        <Filed :name="$t('xi-guan')"
               :desc="`${$t('mu-qian-sheng-yu')}${isShipment? serverData.strawStock : serverData.strawStockBefore}根`"
               :buttonShow="isShipment"
               @onClickFull="clickFull"
               typeOrIndex="straw">
          <van-stepper  v-if="isShipment" :min="serverData.strawStock" max="200" v-model="serverData.$straw" />
          <van-stepper  v-else :min="0" max="200" v-model="serverData.strawStockAfter" />
        </Filed>
        <div class="line"></div>
        <Filed :name="$t('bei-gai')"
               :desc="`${$t('mu-qian-sheng-yu')}${isShipment?  serverData.cupCoverStock : serverData.cupCoverStockBefore}个`"
               :buttonShow="isShipment"
               @onClickFull="clickFull"
               typeOrIndex="cupCover">
          <van-stepper  v-if="isShipment" :min="serverData.cupCoverStock" max="200" v-model="serverData.$cupCover" />
          <van-stepper  v-else :min="0" max="200" v-model="serverData.cupCoverStockAfter" />
        </Filed>
      </div>
    </Card>

    <Card class="shment-wrapper shipment-wrapper-last">
      <div class="inner">
        <div class="title">{{$t('guo-jiang')}}</div>
        <div v-for="(p, index) in isShipment? serverData.refillPumpDtoList : serverData.checkPumpDtoList" :key="p.pumpCode">
          <Filed :name="p.jamName? p.jamName : $t('zan-wei-pei-zhi')"
                 :desc="`${$t('mu-qian-sheng-yu')}${isShipment? p.jamStock : p.jamStockBefore}ML`"
                 :buttonShow="isShipment"
                 @onClickFull="clickFull"
                 :typeOrIndex="String(index)">
            <van-stepper  v-if="isShipment" :min="p.jamStock"  :max="p.jamName? p.jamCapacity : 0" v-model="p.$stock" />
            <van-stepper  v-else :min="0"  :max="p.jamName? p.jamCapacity : 0" v-model="p.jamStockAfter" />
          </Filed>
          <div v-if="(isShipment? serverData.refillPumpDtoList.length : serverData.checkPumpDtoList.length) !== index + 1" class="line"></div>
        </div>
      </div>
    </Card>

    <BottomBar @onClickOne="cancle" @onClickTwo="submit"/>

    <van-overlay class="juice-screen-loading" :show="loading" >
      <van-loading style="color: #ffffff" size="40px" vertical><span style="color: #ffffff">{{$t('zheng-zai-zhun-bei-shu-ju')}}...</span></van-loading>
    </van-overlay>

    <TipsDialog :config="tipConfig"/>

  </div>
</template>


<script>
import Card from '@/components/Card'
import TipsDialog from '@/components/TipsDialog'
import Filed from '../components/Filed'
import BottomBar from '@/components/BottomBar'
import { getRefill, saveRefill, getCheck, saveCheck } from '@/api/refill'
import { refillMixing } from '@/mixins/refill'
export default {
  name: "ReplenishmentPump",
  mixins: [ refillMixing ],
  components: {
    Card,
    Filed,
    BottomBar,
    TipsDialog
  },
  mounted() {
    this.isShipment = String(this.$route.query.isShipment) == 'true'
    this.deviceName = this.$route.query.deviceName
    this.fetch()
  },
  data() {
    return {
      deviceName: '',
      isShipment: true,
      tipConfig: {
        type: 'pump',
        title: this.$t('yuan-liao-cao-zuo-ti-shi'),
        tips: [
          this.$t('bu-huo-zhi-yun-xu-zeng-jia-shu-liang-ru-xu-zhi-hang-jian-shao-cao-zuo-qing-xuan-ze-pan-dian'),
          this.$t('dian-ji-shu-liang-ke-yi-zhi-jie-shu-ru'),
          this.$t('chang-an-jia-hao-huo-zhe-jian-hao-ke-kuai-su-shu-ru'),
        ]
      }
    };
  },
  methods: {
    async fetch() {
      this.loading = true
      if (this.isShipment) {
        const { data } = await getRefill(this.deviceId)
        this.serverData = data
        const { waterStock1, waterStock2, waterStock3, strawStock, cupCoverStock } = this.serverData

        this.$set(this.serverData, '$water1', waterStock1)
        this.$set(this.serverData, '$water2', waterStock2)
        this.$set(this.serverData, '$water3', waterStock3)
        this.$set(this.serverData, '$straw', strawStock)
        this.$set(this.serverData, '$cupCover', cupCoverStock)

        this.serverData.refillPumpDtoList.forEach(pump => {
          this.$set(pump, '$stock', pump.jamStock)
        })
      } else {
        const { data } = await getCheck(this.deviceId)
        this.serverData = data
      }

      this.loading = false
    },
    async submit() {
      this.loading = true

      if (this.isShipment) {
        let { $water1,$water2,$water3, $straw, $cupCover, waterStock1,waterStock2,waterStock3, strawStock, cupCoverStock } = this.serverData
        this.serverData.waterQty1 = $water1 - waterStock1
        this.serverData.waterQty2 = $water2 - waterStock2
        this.serverData.waterQty3 = $water3 - waterStock3
        this.serverData.strawQty = $straw - strawStock
        this.serverData.cupCoverQty = $cupCover - cupCoverStock

        this.serverData.refillPumpDtoList.forEach(pump => {
          pump.jamQty = pump.$stock - pump.jamStock
        })
      }

      try {
        if (this.isShipment) {
          await saveRefill(this.serverData)
        } else {
          await saveCheck(this.serverData)
        }
        this.pushStatusPage(true, this.isShipment)
      } catch(e) {
        this.pushStatusPage(false, this.isShipment)
      }

      this.loading = false
    },
    clickFull(typeOrIndex) {
      const index = Number(typeOrIndex)
      if (!Number.isNaN(index)) {
        const selectPump = this.serverData.refillPumpDtoList[index]
        if (selectPump && selectPump.jamName) {
          selectPump.$stock = selectPump.jamCapacity
        }
        return
      }
      switch(typeOrIndex) {
        case 'water3':
          this.serverData.$water3 = 18000
          break;
        case 'water2':
          this.serverData.$water2 = 18000
          break;
        case 'water1':
          this.serverData.$water1 = 18000
          break;
        case 'straw':
          this.serverData.$straw = 200
          break;
        case 'cupCover':
          this.serverData.$cupCover = 3000
          break;

      }

    },
  }
};
</script>

<style lang="less" scoped>
.wrapper {
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /deep/ .van-stepper__input {
    width: 40px !important;
    background: white !important;
  }
  .name {
    width: 343px;
    color: @color-grey;
    font-size: 14px;
    display: flex;
    align-items: center;
    .icon {
      margin-right: 3px;
    }
  }
  .shment-wrapper {
    width: 343px;
    margin-top: 12px;
    .inner {
      margin: 16px;
      color: @color-black;
      .title {

        font-weight: bold;
        font-size: 18px;
      }
      .line {
        margin-top: 16px;
        border-top: 1px solid #EBEDEB;
      }
      .last {
        padding-bottom: 16px;
      }
    }
  }
  .shipment-wrapper-last {
    margin-bottom: 66px;
  }
  .bottom-wrapper {
    display: flex;
    position: fixed;
    bottom: 0;
    font-size: 16px;
    color: white;
    text-align: center;
    line-height: 56px;
    .button {
      width: 50vw;
      flex: 1;
      height: 56px;
      background: @main-color;
    }
    .cancle {
      color: @main-color;
      background: #EBFFE9;
    }
  }
}
</style>
