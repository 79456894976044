import My from '@/page/home/My'
import AboutUs from '@/page/my/AboutUs'
import Setting from '@/page/my/Setting'

const path = '/my'

export default [
  {
    path: path,
    name: 'my',
    component: My,
    meta: {
      title: '我的'
    }
  },
  {
    path: `${path}/about`,
    name: 'AboutUs',
    component: AboutUs,
    meta: {
      title: '关于我们'
    }
  },
  {
    path: `${path}/setting`,
    name: 'Setting',
    component: Setting,
    meta: {
      title: '设置'
    }
  },
]