import Vue from 'vue'
import PermissionComponents from '@/page/PermissionForbid.vue'
import { addClass, removeClass } from '@/utils/dom'

// directive
const toogleShow =  (el, binding) => {
 
    const permissionDom = el.getElementsByClassName('permission-forbid-dom-wrapper')
    const parent = el

    if(binding.value) {
        // 还要保证 加上一个相对定位的class
        addClass(el, 'juice-permission-forbid-position--relative')
        if (permissionDom.length > 0) {
            permissionDom[0].style.display = 'block'
        } else {
            Vue.nextTick(() => {
                insertDom(parent, el, binding)
            })
        }
    } else {
        removeClass(el, 'juice-permission-forbid-position--relative')
        permissionDom[0].style.display = 'none'
        el.instance.visible = false
        el.instance.hiding = true
    }
}

const insertDom = (parent, el) => {
    parent.appendChild(el.mask)
    el.instance.visible = true
}

const Mask = Vue.extend(PermissionComponents);
Vue.directive('permission', {
    bind: function(el, binding) {
        const mask = new Mask({
            el: document.createElement('div'),
            data: {}
        })
        el.instance = mask;
        el.mask = mask.$el;
        el.maskStyle = {};
    
        binding.value && toogleShow(el, binding)
    },

    update: function(el, binding) {
        if (binding.oldValue !== binding.value) {
            toogleShow(el, binding);
        }
    }
})
 