<template>
  <div class="page">
    <HomeTopCard ref="" class="detail-top-card loading-ontainer">
        <template #header>
          <van-tabs  @click="tabClick" :border="false" title-inactive-color="#FFFFFF" title-active-color="#FFFFFF" color="#F5AD35" background="#6BC263" v-model="tabActive" >
            <van-tab :title="item.name" v-for="item in tabs" :key="item.queryParams">
            </van-tab>
          </van-tabs>
        </template>
        <template #main>
          <div class="top">{{$t('xiao-shou-zong-e')}}</div>
          <div class="center">
            <div v-if="topTabData.totalAmount != undefined">{{ topTabData.totalAmount }}</div>
            <div v-else><van-loading type="spinner" size="24px" /></div>
           <!-- <div class="percent">15%</div> -->
           </div>
          <div class="bottom">
            <div class="item">
              <div class="number" v-if="topTabData.perAmount != undefined" >{{ topTabData.perAmount }}</div>
              <div class="number" v-else> <van-loading type="spinner" size="24px" /> </div>
              <div class="desc">{{$t('ren-jun-xiao-fei')}}</div>
            </div>
            <div class="item">
              <div class="number" v-if="topTabData.totalOrderCount != undefined" >{{ topTabData.totalOrderCount }}</div>
              <div class="number" v-else> <van-loading type="spinner" size="24px" /> </div>
              <div class="desc">{{$t('jiao-yi-bi-shu')}}</div>
            </div>
            <div class="item">
              <div class="number" v-if="topTabData.agoTotalAmount != undefined" >{{ topTabData.agoTotalAmount }}</div>
              <div class="number" v-else> <van-loading type="spinner" size="24px" /> </div>
              <div class="desc">{{$t('huan-bi-zeng-chang')}}</div>
            </div>
          </div>
        </template>
    </HomeTopCard>
    <div class="block">
      <div class="title">
        <div class="prefix">{{$t('she-bei-li-xian-zai-xian-shu-ju')}}</div>
        <div class="surfix" @click="onDeviceBlockClick">{{$t('cha-kan-xiang-qing')}} ></div>
      </div>
      <div class="card-wrapper">
        <DeviceBlock :title="$t('li-xian-she-bei')" icon="icon-disconnect" :number="deviceCount.offline" type="stop"/>
        <DeviceBlock :title="$t('zai-xian-she-bei')" icon="icon-sign" :number="deviceCount.online" type="online"/>
      </div>
    </div>
    <div class="block">
      <div class="title">
        <div class="prefix">{{$t('she-bei-que-huo-shu-ju')}}</div>
        <div class="surfix"  @click="onDeviceLowClick">{{$t('cha-kan-xiang-qing')}} ></div>
      </div>
      <div class="card-wrapper">
        <DeviceBlock :title="$t('shang-pin-que-huo-she-bei')" icon="icon-stock" :number="lowStockDeviceCount.productDeviceCount" type="shipment"/>
        <DeviceBlock :title="$t('yuan-liao-que-huo-she-bei')" icon="icon-jam"  :number="lowStockDeviceCount.productSourceCount" type="shipmentProduct"/>
      </div>
    </div>
    <div class="block">
      <div class="title">
        <div class="prefix">{{$t('xiao-shou-ding-dan-shu-ju')}}</div>
        <div class="surfix" @click="onOrderBlockClick">{{$t('cha-kan-xiang-qing')}} ></div>
      </div>
      <div class="card-wrapper">
        <card class="order-card">
           <ve-line :data="orderChart.chartData" :settings="orderChart.chartSettings"></ve-line>
        </card>
      </div>
    </div>
 
    <BottomTab />
  </div>
</template>

<script>
import BottomTab from './components/BottomTab'
import Card from '@/components/Card'
import DeviceBlock from '@/components/DeviceBlock'
import HomeTopCard from './components/HomeTopCard'
import { deviceCountByType, deviceCountOfLowStock } from '@/api/device'
import { orderStatistics, lineChartData } from '@/api/order'
export default {
  name: "Home",
  components: {
    BottomTab,
    Card,
    DeviceBlock,
    HomeTopCard,
  },
  data() {
    return {
      loading: true,
      tabActive: undefined,
      bottonTabActive: undefined,
      topTabData: {
        perAmount: undefined,
        totalAmount: undefined,
        totalOrderCount: undefined,
        agoTotalAmount: undefined,
      },
      tabs: [
        {
          name: this.$t('jin-ri'),
          queryParams: '1'
        },
        {
          name: this.$t('zuo-ri'),
          queryParams: '2'
        },
        {
          name: this.$t('ben-zhou'),
          queryParams: '6'
        },
        {
          name: this.$t('ben-yue'),
          queryParams: '3'
        }
      ],
      orderChart: {
        chartData: {
          columns: [],
          rows: [
          ]
        },
        chartSettings: {
          metrics: [this.$t('jin-7-tian'), this.$t('shang-ge-7-tian')],
          dimension: [this.$t('xing-qi')],
          area: true
        }
      },
      deviceCount: {},
      lowStockDeviceCount: {},
    };
  },
  computed: {},
  async mounted() {
    this.tabClick(0)

    const { data } = await deviceCountByType()
    this.deviceCount = data

    // 订单图开始
    const { data: lineData} = await lineChartData()
    const filterLineData = []
    for (let i = 0; i < lineData.lineChartData.payAmount.newData.length; i++) {
      let d = {
        '星期': lineData.dataText[i],
        '近7天': lineData.lineChartData.payAmount.newData[i],
        '上个7天': lineData.lineChartData.payAmount.oldData[i],
      }
      filterLineData.push(d)
    }
    this.orderChart.chartData.rows = filterLineData
    // 订单图结束

    const { data: lowStockDeviceCount } = await deviceCountOfLowStock()
    this.lowStockDeviceCount = lowStockDeviceCount
  },
  methods: {
    async fetchTopTab(params) {
      this.topTabData = {
        perAmount: undefined,
        totalAmount: undefined,
        totalOrderCount: undefined,
        agoTotalAmount: undefined,
      }
      const { data } = await orderStatistics(params)
      this.topTabData = data
    },
    onDeviceBlockClick () {
      this.$router.push('device')
    },
    onDeviceLowClick() {
      this.$router.push('device/shipmenInventorytList?shipment=true')
    },
    onOrderBlockClick() {
      this.$router.push('order')
    },
    tabClick(index) {
      this.fetchTopTab(this.tabs[index].queryParams)
    },
    getLoadingContainer() {
      return document.querySelector('.loading-ontainer');
    }
  }
};
</script>

<style lang="less" scoped>

  /deep/ .van-tabs__line{
    width: 12px !important;
  }
  
  /deep/ .van-tab {
    flex: 0 0 auto !important;
    padding: 0 12px!important;
  }
  .page {
    padding-bottom: 50px;
    .detail-top-card {
      .bottom {
        margin-top: 32px;
      }
    }
    .block {
      font-size: 12px;
      margin: 0 16px;
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .prefix {
          color: @color-black;
          font-size: 18px;
          font-weight: bold;
        }
        .surfix {
          color: #C8CCC8;
          font-size: 14px;
        }
      }
      .card-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
        margin-top: 16px;
        .order-card {
          
          width: 100%;
        }
      }
  }
}

</style>