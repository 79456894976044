import { listDevice } from '@/api/device'

export const deviceFetchMixing = {
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        keywords: undefined,
        deviceName: undefined,
        deviceCode: undefined,
        salesStatus: undefined,
        onlineStatus: undefined,
        errorStatus: undefined,
      },
    };
  },
  methods: {
    injectFetch() {
      return listDevice(this.queryParams)
    },
    onSearch(keywords) {
      this.queryParams = {
        pageNum: 1,
        pageSize: 10,
        keywords: undefined,
        deviceName: undefined,
        deviceCode: undefined,
        salesStatus: undefined,
        onlineStatus: undefined,
        errorStatus: undefined,
      }
      // 还需要把上面那几个block过滤掉
      if (this.deviceBlockStatus) {
        this.deviceBlockStatus = {
          onlineSelected: false,
          offlineSelected: false,
          errorSelected: false,
          stopSelected: false,
        }
      }
      this.queryParams.keywords = keywords
      this.list = []
      this.fetch()
    },
  }
}