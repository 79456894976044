import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from './store/index'
import dao from './store/dao'
import Vant from 'vant';
import VeLine from 'v-charts/lib/line.common'
import VeBar from 'v-charts/lib/bar.common'
import '@/rem'

import 'vant/lib/index.css';
import '@/css/iconfont.css';

import permission from './directive/permission'
import './permission'
import './permissionDirective'

import '@/utils/date'


// import { Locale } from 'vant';
// import enUS from 'vant/es/locale/lang/en-US';
import VueI18n from 'vue-i18n'

// Locale.use('en-US', enUS);
Vue.use(VueI18n)

Vue.prototype.$dao = dao

Vue.use(permission)

Vue.config.productionTip = false

Vue.use(Vant)

Vue.component(VeLine.name, VeLine)
Vue.component(VeBar.name, VeBar)



const i18n = new VueI18n({
  locale: 'zh', 
  messages: {   
    'zh': require('./i18n/zh.json'),   
    'en': require('./i18n/en.json') 
  }
})


new Vue({
  render: h => h(App),
  router,
  store,
  i18n
}).$mount('#app')
