
export const refillMixing = {
  mounted() {
    if (!this.$route.query.deviceId) {
      this.$toast.fail('补货参数错误，请重试');
      this.$router.replace('/home')
      return
    }
    this.deviceId = this.$route.query.deviceId
  },
  data() {
    return {
      deviceId: undefined,
      loading: false,
      // 存服务器的原始数据
      serverData: {}
    };
  },
  methods: {
    pushStatusPage(isSuccess, isShipment = true) {
      let query = {}
      if (isSuccess) {
        query.type = 'success'
        query.title = `${isShipment? '补货' : '盘点'}成功`
        query.desc = '请继续操作'
      } else {
        query.type = 'error'
        query.title = `${isShipment? '补货' : '盘点'}失败`
        query.desc = '请重新尝试'
      }
      console.log('准备push')
      this.$router.push({path: '/statusPage', query})
    },
    cancle() {
      this.$router.go(-1)
    }
  }
}