<template>
  <div class="wrapper">
 
    <div class="img">
      <img v-if="type === 'success' " src="@/assets/icon_success.png" alt="">
      <img v-else src="@/assets/icon_error.png" alt="">
    </div>
    <div class="title title-success" :class="{ 'title-error' : type === 'error'}">
      {{ this.title }}
    </div>
    <div class="desc">
      {{ this.desc }}
    </div>
    <van-button @click="reback" class="button button-success" :class="{ 'button-error' : type === 'error'}"  type="danger">{{$t('fan-hui-shou-ye')}}</van-button>
   
  </div>
</template>

<script>
 
export default {
  name: "StatusPage",
  data() {
    return {
      type: '',
      title: '',
      desc: ''
    };
  },
  mounted() {
    this.type = this.$route.query.type || 'error'
    this.title = this.$route.query.title || this.$t('cao-zuo-shi-bai')
    this.desc = this.$route.query.desc || this.$t('cao-zuo-shi-bai-qing-shao-hou-zai-shi')
  },
  methods: {
    reback() {
      this.$router.replace('/home')
    }
  }
};
</script>

<style lang="less" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  .img {
    margin-top: 73px;
    img {
      width: 120px;
 
    }
  }
  .title {
    margin-top: 24px;
    font-size: 18px;
    font-weight: bold;
  }
  .title-success {
    color: @main-color;
  }
  .title-error {
    color: @color-red;
  }
  .desc {
    text-align: center;
    margin-top: 4px;
    color: @color-grey;
    width: 303px;
    font-size: 14px;
    line-height: 22px;
  }
  .button {
    margin-top: 40px;
    width: 343px;
    background: @color-red;
    border: none;
  }
  .button-success {
    background: #EBFFE9;
    color: @main-color;
  }
  .button-error {
    background: #FFECE9;
    color: @color-red;
  }
}

</style>
