<template>
  <i class="iconfont" :class="name" :style="{ color: color, fontSize: size}"></i>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      default: "icon-sign"
    },
    color: {
      type: String,
      default: '#6BC263'
    },
    size: {
      type: String,
      default: '24px'
    }
  }
}
</script>

 
<style lang="less" scoped >
 
</style>
