import request from '@/utils/request'

// 登录方法
export function login(data) {
  // const data = {
  //   username,
  //   password,
  //   code,
  //   uuid,
  //   from: 'mobile'
  // }
  return request({
    url: '/login',
    method: 'post',
    data: data
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/getInfo',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    method: 'get'
  })
}
// 绑定微信号
export function bindWechat(openId) {
  return request({
    url: '/system/user/bindWechat/' + openId,
    method: 'get'
  })
}
