<template>
  <div class="operate-wrapper">
      <Card @click.native="f.target(f)"  v-for="f in functions" :key="f.id" class="item">
        <div class="icon">
          <img :src="f.icon" alt="">
        </div>
        <div class="name">
          {{f.name}}
        </div>
      </Card>
  </div>
</template>

<script>
import Card from '@/components/Card'
export default {
  name: "FunctionsGrid",
  components: {
    Card
  },
  props: {
    functions: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  created() {
    this.functions.forEach(item => {
      if (!item.target) {
        item.target = this.click
      }
    })
  },
  methods: {
    click() {
    }
  }
};
</script>

<style lang="less" scoped>
.operate-wrapper {
  padding-top: 12px;
  display: grid;
  grid-template-columns: repeat(3, 33.33%);
  grid-row-gap: 12px;
  justify-items: center;
  .item {
    width: 106px;
    height: 118px;
    text-align: center;
    .icon {
      padding-top: 20px;
      img {
        width: 56px;
        height: 56px;
      }
    }
    .name {
      font-size: 14px;
      color: @color-grey;
    }
  }
}
</style>
