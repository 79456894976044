<template>
  <div class="wrapper">
    <van-collapse v-model="activeNames" accordion>
      <van-collapse-item :title="$t('chang-gui-yuan-liao')" name="1">
        <div v-if="isShipment">
          <van-cell :title="$t('shui1')"  :value="`${ refillData.waterQty1 != null ? refillData.waterQty1 + 'ML' : '无变化' }`" />
          <van-cell :title="$t('shui2')"  :value="`${ refillData.waterQty2 != null ? refillData.waterQty2 + 'ML' : '无变化' }`" />
          <van-cell :title="$t('shui3')"  :value="`${ refillData.waterQty3 != null ? refillData.waterQty3 + 'ML' : '无变化' }`" />
          <van-cell :title="$t('xi-guan')" :value="`+ ${ refillData.strawQty } 根`" />
          <van-cell :title="$t('bei-gai')" :value="`+ ${ refillData.cupCoverQty } 个`" />
        </div>
        <div v-else>
          <van-cell :title="$t('shui')" :value="checkData.waterMsg" />
          <van-cell :title="$t('xi-guan')" :value="checkData.strawMsg" />
          <van-cell :title="$t('bei-gai')" :value="checkData.cupCoverMsg" />
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('shang-pin')" name="2">
        <div v-if="isShipment">
          <span v-if="refillData.refillRailDtoList.length === 0">{{$t('zan-wu')}}</span>
          <van-cell :title="r.productName"  :value="`+ ${ r.productQty }`" v-for=" (r, index) in refillData.refillRailDtoList" :key="index" />
        </div>
        <div v-else>
          <span v-if="checkData.checkRailDtoList.length === 0">{{$t('zan-wu')}}</span>
          <van-cell :title="r.productName"  :value="r.msg" v-for=" (r, index) in checkData.checkRailDtoList" :key="index" />
        </div>
      </van-collapse-item>
      <van-collapse-item :title="果酱" name="3">
        <div v-if="isShipment">
          <span v-if="refillData.refillPumpDtoList.length === 0">{{$t('zan-wu')}}</span>
          <van-cell :title="p.jamName"  :value="`+ ${ p.jamQty } ML`" v-for=" p in refillData.refillPumpDtoList" :key="p.pumpCode" />
        </div>
        <div v-else>
          <span v-if="checkData.checkPumpDtoList.length === 0">{{$t('zan-wu')}}</span>
          <van-cell :title="p.jamName"  :value="p.msg" v-for=" p in checkData.checkPumpDtoList" :key="p.pumpCode" />
        </div>
      </van-collapse-item>

    </van-collapse>
    <div class="tips" v-if="isShipment">
      <div class="name"> {{ refillData.deviceName }}</div>
      <div class="time"> {{ refillData.createTime }}</div>
    </div>
    <div class="tips" v-else>
      <div class="name"> {{ checkData.deviceName }}</div>
      <div class="time"> {{ checkData.createTime }}</div>
    </div>

    <van-overlay class="juice-screen-loading" :show="loading">
      <van-loading />
    </van-overlay>
  </div>
</template>

<script>

import { refillDto, checkDto } from '@/api/refill'



export default {
  name: "ShipmentDesc",
  computed: {
    checkFilterNull() {
      return (filed) => {
        if (filed) {
          return filed
        } else {
          return this.$t('wu-bian-hua')
        }
      }
    }
  },
  mounted() {
    this.id = this.$route.query.id
    this.isShipment = String(this.$route.query.isShipment) == 'true'
    this.fetch()
  },
  data() {
    return {
      activeNames: '1',
      isShipment: true,
      id: '',
      refillData: {
        refillRailDtoList: [],
        refillPumpDtoList: []
      },
      checkData: {
        checkRailDtoList: [],
        checkPumpDtoList: []
      },
      loading: false
    };
  },
  methods: {
    async fetch() {
      this.loading = true
      function nullToZero(filed) {
        if (filed == null || filed == 'null') {
          return 0;
        }
        return filed
      }
      if (this.isShipment) {
        const { data } = await refillDto(this.id)
        this.refillData = data
      } else {

        const { data } = await checkDto(this.id)
        // 需要fillternull
        let { waterStockAfter, waterStockBefore, strawStockAfter, strawStockBefore, cupCoverStockAfter,
         cupCoverStockBefore} = data
        data.waterStockAfter = nullToZero(waterStockAfter)
        data.waterStockBefore = nullToZero(waterStockBefore)
        if (data.waterStockAfter - data.waterStockBefore > 0) {
          data.waterMsg = `${this.$t('pan-ying')} ${data.waterStockAfter - data.waterStockBefore} ML`
        } else if (data.waterStockAfter - data.waterStockBefore < 0) {
          data.waterMsg = `${this.$t('pan-kui')} ${Math.abs(data.waterStockAfter - data.waterStockBefore)} ML`
        } else {
          data.waterMsg = this.$t('wu-bian-hua')
        }

        data.strawStockAfter = nullToZero(strawStockAfter)
        data.strawStockBefore = nullToZero(strawStockBefore)
        if (data.strawStockAfter - data.strawStockBefore > 0) {
          data.strawMsg = `${this.$t('pan-ying')} ${data.strawStockAfter - data.strawStockBefore} ` + this.$t('gen')
        } else if (data.strawStockAfter - data.strawStockBefore < 0) {
          data.strawMsg = `${this.$t('pan-kui')} ${Math.abs(data.strawStockAfter - data.strawStockBefore)} ` + this.$t('gen')
        } else {
          data.strawMsg = this.$t('wu-bian-hua')
        }

        data.cupCoverStockAfter = nullToZero(cupCoverStockAfter)
        data.cupCoverStockBefore = nullToZero(cupCoverStockBefore)
        if (data.cupCoverStockAfter - data.cupCoverStockBefore > 0) {
          data.cupCoverMsg = `${this.$t('pan-ying')} ${data.cupCoverStockAfter - data.cupCoverStockBefore} ` + this.$t('ge')
        } else if (data.cupCoverStockAfter - data.cupCoverStockBefore < 0) {
          data.cupCoverMsg = `${this.$t('pan-kui')} ${Math.abs(data.cupCoverStockAfter - data.cupCoverStockBefore)} ` + this.$t('ge')
        } else {
          data.cupCoverMsg = this.$t('wu-bian-hua')
        }


        data.checkRailDtoList = data.checkRailDtoList.filter(rail => {
          return rail.productName
        })
        data.checkRailDtoList.forEach(rail => {
          if (rail.railStockAfter - rail.railStockBefore > 0) {
            rail.msg = `${this.$t('pan-ying')} ${rail.railStockAfter - rail.railStockBefore} ` + this.$t('ge')
          } else if (rail.railStockAfter - rail.railStockBefore < 0) {
            rail.msg = `${this.$t('pan-kui')} ${Math.abs(rail.railStockAfter - rail.railStockBefore)} ` + this.$t('ge')
          } else {
            rail.msg = this.$t('wu-bian-hua')
          }
        });

        data.checkPumpDtoList = data.checkPumpDtoList.filter(pump => {
          return pump.jamName
        })
        data.checkPumpDtoList.forEach(pump => {
          if (pump.jamStockAfter - pump.jamStockBefore > 0) {
            pump.msg = `${this.$t('pan-ying')} ${pump.jamStockAfter - pump.jamStockBefore} ML`
          } else if (pump.jamStockAfter - pump.jamStockBefore < 0) {
            pump.msg = `${this.$t('pan-kui')} ${Math.abs(pump.jamStockAfter - pump.jamStockBefore)} ML`
          } else {
            pump.msg = this.$t('wu-bian-hua')
          }

        });
        this.checkData = data

      }
      this.loading = false
    }
  }
};
</script>

<style lang="less" scoped>
.wrapper {
  background: @main-container-bk-color;
  .tips {
    margin-top: 24px;
    text-align: center;
    font-size: 12px;
    color: @color-grey;
    .time {
      margin-top: 12px;
    }
  }

}
</style>
