import router from './router'
import store from './store'
import { getToken } from '@/utils/auth'

const whiteList = ['/login', '/auth-redirect', '/bind', '/register']

router.beforeEach((to, from, next) => {
  let title = to.meta.title
  if (!title) {
    title = '果普拉斯'
  }
  window.document.title = title
  
  if (getToken()) {
    /* has token*/
    if (to.path === '/login') {
      next()
      
    } else {
      if (store.state.user.roles.length === 0) {
        // 判断当前用户是否已拉取完user_info信息
        store.dispatch('user/GetInfo').then(res => {
          // 拉取user_info
          const roles = res.roles
          store.dispatch('GenerateRoutes', { roles }).then(() => {
            // 根据roles权限生成可访问的路由表
            // router.addRoutes(accessRoutes) // 动态添加可访问路由表
            // next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
            next() // hack方法 确保addRoutes已完成
          })
        })
        .catch(err => {
          console.log(err)
          // store.dispatch('FedLogOut').then(() => {
          //  // Message.error(err)
          //  console.log(err)
          //   next({ path: '/' })
          // })
        })
      } else {
        next()
        // 没有动态改变权限的需求可直接next() 删除下方权限判断 ↓
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next(`/login`) // 否则全部重定向到登录页
    //  NProgress.done()
    }
  }
})

router.afterEach(() => {
 // NProgress.done()
})