<template>
  <div class="device-confirm-wrapper">
    <div class="order-wrapper" >
 
      <div class="order-desc" >
        <div class="order-item" v-for="item in orderInfo.orderItemDtoList" :key="item.id">
          <div class="left">

            <div>
              <img :src="item.smallImg" alt="">
            </div>
            <div class="center">
                <div class="name">
                  {{ item.productName }}
                </div>
                <div class="types">
                  <div class="type">{{item.sugarTypeText}}</div>
                </div>
 
                <div class="prices">
                  <span>
                    ¥{{item.productPriceYuan}}
                  </span>
                  
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="desc-block">
      <div class="prefix">订单总额</div>
      <div class="surfix">¥{{orderInfo.totalAmountYuan}}</div>
    </div>
    <div class="desc-block">
      <div class="prefix">账户余额</div>
      <div class="surfix">¥{{accountBalance}}</div>
    </div>
    <div class="bottom">
      <div class="prefix">
        <div class="price">
          ¥{{orderInfo.totalAmountYuan}}
        </div>
      </div>
      <div class="order-button" type="primary" size="small" @click="comfirmOrder">
        确认
      </div>
    </div>
  </div>
</template>

<script>
// import Title from './components/Title'
import {getOrder, payCustomerOrder} from "@/api/order";
import {getMyBalance} from "@/api/user";
import { Notify } from 'vant';
export default {
  name: "DeviceErrandCustomer",
  components: {
    // Title
  },
  data() {
    return {
      orderShow: true,
      remake: '',
      value: 1,
      orderId: undefined,
      orderInfo: {},
      accountBalance: 0
    };
  },
  async mounted() {

  },
  created() {
    this.orderId = this.$route.query.orderId
    this.getOrderData()
  },
  methods: {
    getOrderData() {
      getOrder(this.orderId).then(res => {
        if (res.code == 200) {
          this.orderInfo = res.data
        }
      })
      getMyBalance().then(res => {
        if (res.code == 200) {
          this.accountInfo = res.data
        }
      })
    },
    toOrderSuccess() {
      this.$router.push({ path: "./OrderSuccess"});
    },
   comfirmOrder() {
     if (this.accountBalance < this.orderInfo.totalAmountYuan * 1) {
       Notify({ type: 'warning', message: '账户余额不足，请充值后下单' });
       return
     }
     // 使用余额支付
      payCustomerOrder(this.$route.query.orderId).then((res) => {
            if (res.code == 200) {
                Notify({ type: 'success', message: '操作成功, 请关注制作进度！' });
                this.toOrderSuccess()
            }
          });
   }
  }
};
</script>

<style lang="less" scoped>
.device-confirm-wrapper {
  padding-bottom: 80px;
  .order-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 16px;
    padding-top: 8px;
    

 
    .order-desc {
      background: white;
      border-radius: 4px;
      .order-item {
        margin: 16px;
        background: white;
        height: 80px;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .left {
          display: flex;
          img {
            height: 80px;
            width: 80px;
          }
          .center { 
            margin-left: 12px;
            .name {
              color: #303331;
              font-size: 16px;
              font-weight: bold;
            }
            .types {
              margin-top: 4px;
              font-size: 12px;
              display: flex;
              .type {
                width: 38px;
                height: 20px;
                color: #949997;
                font-size: 11px;
                text-align: center;
                line-height: 20px;
                background: #F5F8F7;
              }
            }
           .prices {
             margin-top: 12px;
              color: #FF7C6B;
              font-size: 16px;
              font-weight: bold;
              .discount {
                font-weight: normal;
                font-size: 12px;
                color: #C8CCCA;
                text-decoration: line-through
              }
            }
          }
        }
        .right {
          display: flex;
          flex-direction: column-reverse;
          .button {
            width: 64px;
            height: 26px;
            font-size: 12px;
            padding: 0px;
            margin-bottom: 4px;
            position: relative;
            background: @main-color;
          }
          .badge {
            width: 21px;
            height: 16px;
            position: absolute;
            background: @color-red;
            border: 1px solid #FFFFFF;
            font-size: 11px;
            color: #FFF;
            line-height: 16px;
            border-radius: 15px;
            top: -2px;
            right: -15px;
          }
        }
      }
    }

  }
  .desc-block {
    height: 54px;
    display: flex;
    margin: 0 16px;
    border-radius: 4px;
    background: white;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #626664;
    padding: 0 16px;
 
    margin-top: 8px;
  }
  .bottom {
    width: 100%;
    height: 56px;
    position: fixed;
    bottom: 0;
    background: white;
    border-top: 6px solid @main-container-bk-color;;
    display: flex;
    justify-content: space-between;
    .prefix {
      margin-left: 16px;
      .price {
        margin-top: 4px;
        color: #FF7C6B;
        font-size: 24px;
        font-weight: bold;
        font-family: DINPro;
      }
      .discount {
        color: #949997;
        font-size: 12px;
      }
    }
    .order-button {
      height: 100%;
      padding: 0;
      background: #FFBB33;
      width: 112px;
      font-size: 16px;
      color: #FFF;
      text-align: center;
      line-height: 56px;
      border-radius: 4px;
    }
    
  }
}

</style>
