import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/page/Login'
import Home from '@/page/home/Home'
import Operate from '@/page/home/Operate'
import Order from '@/page/order/Index'
import StatusPage from '@/page/status/Index'

import device from './modules/device'
import my from './modules/my'

Vue.use(Router)

export const constantRoutes = [
    {
      path: '/',
      name: 'index',
      redirect: { name: 'Login' },
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        title: '登录'
      }
    },
    {
      path: '/home',
      name: 'Home',
      component: Home,
      meta: {
        title: '首页'
      }
    },
    {
      path: '/operate',
      name: 'operate',
      component: Operate,
      meta: {
        title: '功能'
      }
    },
    {
      path: '/order',
      name: 'Order',
      component: Order,
      meta: {
        title: '订单'
      }
    },
    {
      path: '/statusPage',
      name: 'StatusPage',
      component: StatusPage
    },
    ...device,
    ...my
  ]
  export default new Router({
    mode: 'history', // 去掉url中的#
    routes: constantRoutes
  })