<template>
  <div @click="onClick" class="filed">
    <div>{{title}}</div>
    <div class="icon"><van-icon name="arrow" /></div>
  </div>
</template>

<script>
export default {
  name: 'Filed',
  props: {
    title: {
      type: String,
      default: '关于我们'
    }
  },
  methods: {
    onClick() {
      this.$emit('click')
    }
  }
}
</script>

 
<style  lang="less" scoped >
.filed {
  color: @color-black;
  font-size: 16px;
  height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 16px;
  .icon {
    color: @color-grey;
  }
}
</style>
