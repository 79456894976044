<template>
  <!-- 这是饭店场景，服务员代替下单 -->
  <div class="device-order-wrapper">
    <!-- <Title /> -->
    <div class="order-wrapper">
      <div class="device-name">
        <img src="@/assets/device/icon_equipment.png" alt="" />
        <van-dropdown-menu active-color="#6bc263">
          <van-dropdown-item
            v-model="deviceCode"
            :options="deviceOptions"
            @change="deviceChange"
          />
        </van-dropdown-menu>
      </div>
      <div class="order-desc">
        <div class="order-item" v-for="(item, index) in productList" :key="item.id">
          <div class="left">
            <div>
              <img :src="item.smallImg" alt="" />
            </div>
            <div class="center">
              <div class="name">
                {{ item.productName }}
              </div>
              <div class="stock">库存：{{ item.stock }}</div>
              <div class="msg">客户信息：{{ item.note }}</div>
            </div>
          </div>

          <div class="right">
            <van-button
              class="button"
              type="primary"
              size="small"
              @click="chooseModel(item, index)"
            >
              选择规格
              <div class="badge" v-if="item.quantity && item.quantity > 0">
                {{ item.quantity }}
              </div>
            </van-button>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="ip">
        <img src="@/assets/device/ip_device_order_customer.png" alt="" />
      </div>
      <div class="last">
        <div class="statistics">
          <div class="price">
            ¥{{totalPrice}}
          </div>
          <div class="number">
            共{{totalCount}}杯
          </div>
        </div>
        <div
          class="order-button"
          @click="confirmOrder"
          type="primary"
          size="small"
        >
          下单
        </div>
      </div>
    </div>
    <van-popup
      round
      closeable
      v-model="orderShow"
      position="bottom"
      class="popup"
    >
      <div class="inner">
        <div class="name">
          {{ currentItem.productName }}
        </div>
        <div class="sugar">
          <div class="tips">
            是否加糖
          </div>
          <div class="sugar-selected">
            <div
              :class="currentItem.sugarType == item.type ? 'sugar-type-selected': 'sugar-type'"
              v-for="item in sugarTypes"
              :key="item.type"
              @click="onSugarChange(item)"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="line"></div>
          <div class="tips">
            
          </div>
          <van-field
            class="remake-field"
            v-model="currentItem.note"
            placeholder="客户信息"
          />
          <div class="tips">
            已选规格：
            <div class="more">
              {{ currentItem.sugarName }}
            </div>
          </div>
          <div class="handler">
            <div class="statistics">
              <div class="price">
                ¥{{currentPrice}}
              </div>
             
            </div>
            <van-stepper button-size="30px" v-model="currentCount" />
          </div>
          <van-button class="cart-button" type="primary" block @click="addToCart()">
            加入购物车
          </van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
// import Title from './components/Title'
import { listMyDevice, getDeviceProduct } from "@/api/device";
import {generateOrder} from "@/api/order";
export default {
  name: "DeviceOrderCustomer",
  components: {},
  data() {
    return {
      orderShow: false,
      remake: "",
      value: 1,
      deviceList: [],
      deviceCode: undefined,
      deviceOptions: [],
      productList: [],
      currentItem: {},
      currentPrice: '',
      currentCount: 1,
      currentIndex: -1,
      totalPrice: 0,
      totalCount: 0,
      sugarTypes: [
        { type: 3, name: "全糖" },
        { type: 2, name: "半糖" },
        { type: 1, name: "不加" },
      ],
    };
  },
  async mounted() {
    this.getMyDevices();
  },
  watch: {
    currentCount(newValue) {
      this.currentPrice = newValue * this.currentItem.priceYuan
    }
  },
  methods: {
    confirmOrder() {
      // 生成订单
      let form = {}
      form.deviceCode = this.deviceCode
      let tempProductList = []
      this.productList.forEach(item => {
        if (item.quantity > 0) {
        let productItem = {}
        productItem.productId = item.id
        productItem.quantity = item.quantity
        productItem.sugar = item.sugarType
        tempProductList.push(productItem)
        }
      })
      if (tempProductList.length == 0) {
          return
      }
      form.baseShoppingCartProductDtoList = tempProductList
      generateOrder(form).then((res) => {
        if (res.code == 200) {
          console.log(res);
        //  this.productList = res.data;
          this.$router.push({ path: "./customer/Confirm",query: {orderId: res.data} });
        }
      });
      //  this.$router.push({ path: "./customer/Confirm" });
    },
    deviceChange(value) {
      console.log(value);
        this.reset()
      this.getMyDeviceProduct();
    },
    reset() {
      this.deviceOptions = []
      this.productList = []
      this.currentItem = []
      this.currentPrice = []
      this.currentCount = 1
      this.currentIndex = -1
      this.totalPrice = 0
      this.totalCount = 0
    },
    onSugarChange(item) {
      console.log(item);
      this.currentItem.sugarType = item.type;
      this.currentItem.sugarName = item.name;
     // this.remake = 2
      this.$forceUpdate()
    },
    chooseModel(item, index) {
      this.currentItem = item;
      this.currentIndex = index
      if (item.quantity) {
        this.currentCount = item.quantity
      } else {
        this.currentCount = 1
        this.currentPrice = this.currentItem.priceYuan
      }
      if (!item.sugarType) {
        this.currentItem.sugarType = 3
        this.currentItem.sugarName = '全糖'
      }
      this.orderShow = true;
    },
    addToCart() {
      let productItem = this.productList[this.currentIndex]
      productItem.quantity = this.currentCount
      productItem.sugarType = this.currentItem.sugarType
      productItem.note = this.currentItem.note
      this.orderShow = false;
      let tempTotalPrice = 0
      let tempTotalCount = 0
      this.productList.forEach(item => {
        if (item.quantity && item.quantity > 0) {
          tempTotalPrice = tempTotalPrice + item.quantity * item.priceYuan
          tempTotalCount = tempTotalCount + item.quantity
        }
      })
      this.totalPrice = tempTotalPrice
      this.totalCount = tempTotalCount
    },
    getMyDevices() {
      listMyDevice().then((res) => {
        if (res.code == 200) {
          console.log(res);
          let tempOptions = [];
          res.data.forEach((item) => {
            let option = {
              text: item.deviceName,
              value: item.deviceCode,
            };
            tempOptions.push(option);
          });
          this.deviceOptions = tempOptions;
          if (res.data.length > 0) {
            this.deviceCode = res.data[0].deviceCode;
            this.getMyDeviceProduct();
          }
        }
      });
    },
    getMyDeviceProduct() {
      getDeviceProduct(this.deviceCode).then((res) => {
        if (res.code == 200) {
          console.log(res);
          this.productList = res.data;

        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.statistics {
  .price {
    color: @color-red;
    font-size: 24px;
    font-weight: bold;
    font-family: DINPro;
    margin-top: 4px;
  }
  .number {
    color: #949997;
    font-size: 12px;
  }
}
/deep/ .van-dropdown-menu__bar {
  background-color: transparent;
  box-shadow: 0 2px 12px transparent;
}
.device-order-wrapper {
  padding-bottom: 80px;
  .order-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 16px;
    padding-top: 4px;
    .device-name {
      display: flex;
      height: 22px;
      align-items: center;
      margin-bottom: 8px;
      img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
      color: @color-grey;
      font-size: 14px;
    }
    .order-desc {
      background: white;

      .order-item {
        margin: 16px;
        background: white;
        height: 80px;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .left {
          display: flex;
          img {
            height: 80px;
            width: 80px;
          }
          .center {
            margin-left: 12px;
            .name {
              color: #303331;
              font-size: 16px;
              font-weight: bold;
            }
            .stock {
              margin-top: 4px;
              color: #949997;
              font-size: 12px;
            }
            .msg {
              margin-top: 8px;
              padding: 2px 12px;
              color: #949994;
              background: #f5f8f5;
              border-radius: 2px;
              font-size: 12px;
            }
          }
        }
        .right {
          display: flex;
          flex-direction: column-reverse;
          .button {
            width: 64px;
            height: 26px;
            font-size: 12px;
            padding: 0px;
            margin-bottom: 4px;
            position: relative;
            background: @main-color;
          }
          .badge {
            width: 21px;
            height: 16px;
            position: absolute;
            background: @color-red;
            border: 1px solid #ffffff;
            font-size: 11px;
            color: #fff;
            line-height: 16px;
            border-radius: 15px;
            top: -2px;
            right: -15px;
          }
        }
      }
    }
  }
  .bottom {
    width: 100%;
    height: 56px;
    position: fixed;
    bottom: 0;
    background: white;
    .ip {
      position: absolute;
      left: 16px;
      top: -18px;
      img {
        height: 68px;
      }
    }
    .last {
      height: 100%;
      display: flex;
      width: 295px;
      margin-left: 80px;
      justify-content: space-between;

      .order-button {
        height: 100%;
        padding: 0;
        background: @main-color;
        width: 112px;
        font-size: 16px;
        color: #fff;
        text-align: center;
        line-height: 56px;
        border-radius: 4px;
      }
    }
  }
  .popup {
    overflow: hidden;
    height: 441px;
    .inner {
      margin: 16px;
      .line {
        border: 1px solid #ebedeb;
        margin: 16px 0px;
      }
      .tips {
        color: @color-grey;
        font-size: 12px;
        margin-bottom: 12px;
        display: flex;
        .more {
          padding: 2px 8px;
          background: #f5f8f5;
        }
      }
      .name {
        color: @color-black;
        font-size: 24px;
        font-weight: bold;
      }
      .sugar {
        margin-top: 32px;
        .sugar-selected {
          display: flex;
          .sugar-type {
            height: 30px;
            width: 60px;
            color: @main-color;
            background: #fff;
            border: 1px solid @main-color;
            font-size: 14px;
            border-radius: 4px;
            margin-right: 16px;
            text-align: center;
            line-height: 30px;
          }
          .sugar-type-selected {
            height: 30px;
            width: 60px;
            color: #ffffff;
            background: @main-color;
            font-size: 14px;
            border-radius: 4px;
            margin-right: 16px;
            text-align: center;
            line-height: 30px;
          }
        }
      }
      .remake-field {
        background: #f5f8f5;
        border-radius: 4px;
        margin-bottom: 32px;
      }
      .handler {
        margin-top: 8px;
        height: 62px;
        align-items: center;
        display: flex;
        justify-content: space-between;
      }
      .cart-button {
        margin-top: 12px;
      }
    }
  }
}
</style>
